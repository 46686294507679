body.we-care {
    .we-care-icon {
        border: 3px solid $white;
        border-radius: 50%;
        width: 123px;
        height: 123px;
        text-align: left;
        margin-bottom: 1.5rem !important;

        @include media-breakpoint-down(sm) {
            margin-top: 2rem !important;
        }
    }

    .ce_hyperlink {
        position: relative;
        margin-bottom: 0.5rem !important;
        &.youtube {
            &::before {
                color: $white;
                // font-size: 20px;
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 50%;
                // border: 3px solid white;
                // border-radius: 50%;
                margin-left: -40px;
                margin-top: -40px;
                transition: margin-top 0.35s ease 0s;
                width: 80px;
                height: 80px;
                // padding: 1rem 0.5rem;
                text-align: center;
                font-family: "beg-icons";
                speak: none;
                font-size: 4rem;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                content: "\e905";
            }
        }
    }
    .ce_linkteaser {
        margin-bottom: 0.5rem !important;
        &.youtube {
            .inside {
                .image_container {
                    position: relative;
                    &::before {
                        color: $white;
                        z-index: 1;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-left: -40px;
                        margin-top: -40px;
                        transition: margin-top 0.35s ease 0s;
                        width: 80px;
                        height: 80px;
                        // padding: 1rem 0.5rem;
                        text-align: center;
                        font-family: "beg-icons";
                        speak: none;
                        font-size: 4rem;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        content: "\e905";
                    }
                    &:hover:before {
                        color: $primary;
                        background-color: #fff;
                        border-radius: 50%;
                        padding: 0.5rem 0.55rem;
                    }
                }
            }
        }
        a {
            text-decoration: none !important;
            color: $white;
        }
        .content {
            margin-top: 1rem;
            p {
                color: $white;
                text-decoration: none;
            }
        }
    }
}
