.huh-list.zukunft  {
  .item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 3rem 0.5rem 3rem;
    background-color: $secondary;
    color: $white;
    width: auto;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    text-align: center;

    &.attachment {
      padding: 0;
      position: relative;
      background-color: transparent;

      .image_container {
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        margin: 0;

        img {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          min-width: 101%;
          min-height: 101%;
        }
      }
    }


    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      padding: 0.2rem 1rem 0.2rem 1rem;
      justify-content: space-between;
      width: 100%;
    }

    .word {
      font-family: $font-family-poppins;
      font-size: 1.8rem;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      font-style: italic;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }

    }

    p {
      font-weight: $font-weight-bold;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0.3rem;
        font-weight: $font-weight-bold;
        font-size: 1rem;
      }

    }

  }

  .ajax-pagination {
    margin-top: 2rem;
    justify-content: center;
    display: flex;

    a.next {
      transform: skew(-10deg);
      font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      text-transform: uppercase;
      text-decoration: none !important;
      background-color: #FFD53B;
      color: #005FA3;
      position: relative;
      border: 0;
      font-size: 1.125rem;
      padding: 0.3rem 2rem;
      font-weight: $font-weight-bold;

      &:hover {
        background-color: $primary;
        color: $white;
      }

    }
  }

  .item:nth-child(5n+1) {
    background: $yellow;
    color: $primary;
  }

  .item:nth-child(6n+2) {
    background: $blue-dark;
    color: $white;
  }

  .item:nth-child(7n+3) {
    background: $blue;
    color: $primary;
  }

  .item:nth-child(8n+4) {
    background: $blue-icon;
    color: $white;
  }

  .item:nth-child(9n+5) {
    background: $secondary;
  }

  @include media-breakpoint-up(sm) {
    .outofthebox {
      left: 50%;
      margin-left: -25vw;
      position: relative;
      right: 50%;
    }
  }

  body > #wrapper #main {
    padding-top: 0rem;
  }
}

// Formular

.mod_submission_reader.future {

  .formhybrid {

    .formbody, .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
  
      @include media-breakpoint-up(sm) {
        .form-group {
          flex: 0 0 50%;
          max-width: 50%;
          padding: 1rem 1rem 0rem 0rem;
        }
      }
      
      .futureMobility, .agreementTestimonial, .privacyJumpTo, .agreement, .attachments, .widget-submit {
        flex: 0 0 100%;
        max-width: 100%;
      }
  
    }

    .widget-submit {
      display: flex;
      justify-content: center;
    }

    .feedback-message, .invalid-feedback {
    color: #000;
    background-color: #FFD53B;
    padding: 0.3rem;
    }

    .invalid-feedback a {
      color: #000;
      text-decoration: none;
      }
    
  }



  .attachments {
    padding: 0.4rem 1rem 0rem 0rem;

    @include media-breakpoint-down(sm) {
        padding: 0.4rem 1rem 0rem 1rem;
    }

      label {
        color: $white;
        font-weight: $font-weight-bold;
        margin-bottom: 0.5rem;
        }
        color: $white;
        }

      label.attachments {
        color: $white;
      }

     .form-group {
      width: 100%;
      padding: 1rem 1rem 0rem 1rem;
      margin-bottom: 0;

      
      
      label {
        color: $white !important;
        font-weight: $font-weight-bold;
        padding: 0rem 0;
      }

      .form-control {
        border-radius: 0.3rem;
      }

      a {
        color: $white;
      }

      select {
        margin: 0;
      }

      &.widget-checkbox {
        label {
          padding: 0;
        }
      }
      

    }

    .autocomplete_results_container {

      min-width: 50%;
  }

    .widget-submit {
      padding: 2rem 1rem;
      button[type=submit] {
        padding: 0.3rem 2rem;
        font-weight: $font-weight-bold;
      }
    }
}

.alert-success, .mod_status_messages .msg-success {
  color: $primary;
  background-color: $white;
  border-color: $yellow;
  font-weight: $font-weight-bold;
  border: 6px solid #FFD53B;
}

.counter {
  font-size: 6rem;
  font-weight: $font-weight-bold;
  color: $primary;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.ce_text.win ul {
  padding-left: 1rem;
}
