body.calendar {
    .ce_linkteaser.box-background {
        .more.btn.btn-primary {
            margin-top: 1.5rem;
        }
    }
}


// .box>.inside>.block a.more-section.more-section:before {
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     content: "";
//     background-color: #000;
//     opacity: 0;
//     transition: opacity .25s linear;
//     z-index: 1;
// }