body.partner {
    .accordion {
        margin-bottom: 1rem;
        width: 100%;

        h5 {
            margin-top: 1.5rem;
        }
    }
}

.mod_huhlist.tl_partner {
    .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .item {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-bottom: 1rem;
            min-height: 275px;

            @media (min-width: 768px){
              flex: 0 0 25%;
              max-width: 25%;
            }
            .inside {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                background-color: #fff;
                    &:hover {
                        cursor: pointer;
                    }
                    &:hover .company {
                        top: -100%;
                        transition: top .5s;
                    }
                .content {
                    padding: 0 15px;
                }
                .company {
                    transition: top .5s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    text-align: center;
                    background-color: #fff;
                    .image {
                        padding: 15px;
                    }
                }
            }
        }
    }
}