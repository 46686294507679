.mod_article {
    &.calendar {
        button:before {
            color: $primary;
        }
        button[type=submit]:hover {
            background-color: #005FA3 !important;
            color: #ffffff !important;
        }
        button:hover:before {
            color: #ffffff;
        }
        .quantity_container {
            input[type=number] {
                border-color: $primary;
                color: $primary;
            }
            &.cart-qty input[type=number] {
                color: $primary;
            }
        }
    }

}

.mod_iso_productlist_slider.slider-magazin .downloadlink .submit {
    color: $white;
}

.bg-primary {
    .mod_iso_productlist_slider.tiny-slider {
        .product {
            .cart-qty input[type=number], .submit {
                color: #fff !important;
            }
            .cart-qty input[type=number]:focus {
                color: #000!important;
            }
        }
        .tns-slide-number {
            .number {
                color: #000000 !important;
            }
        }
    }
}