.leisure-destinations {
  margin-bottom: 4.5rem;

  .col_1 {
    margin-bottom: 0;

    & > .ce_text {
      height: 100%;
      margin-bottom: 0 !important;
    }
  }

  button[data-controls="prev"] {
    display: block;
    // @include media-breakpoint-up(md) {
    //   display: none;
    // }
  }

  button[data-controls="next"] {
    right: 0 !important;
    @include media-breakpoint-up(md) {
      right: -10% !important;
    }
    background-color: #fff;
  }

  .ce_text {
    .text {
      display: flex;
      flex-direction: column;
      height: 100%;

      p:last-child {
        margin-top: auto;
      }
    }
  }

.mod_huhlist,
.mod_huhlist .tiny-slider,
.mod_huhlist .tiny-slider .tns-outer,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh .tns-inner,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh .tns-inner .ce_text,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh .tns-inner .ce_text .details,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh .tns-inner .ce_text .details .title-container,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh .tns-inner .ce_text .details .title-container .img-container,
.mod_huhlist .tiny-slider .tns-outer .tns-ovh .tns-inner .ce_text .details .title-container .img-container img {
  height: 100%;
}
  .tns-ovh {
    .tiny-slider-container {
      margin-bottom: 0;
    }

    .news-item {
      .title-container {
        overflow: hidden;
        position: relative;

        .img-container {
          margin: 0;
          @include media-breakpoint-down(md) {
            max-height: 35rem;
          }

          img {
            max-height: 35rem;
            object-fit: cover;
            width: 100%;
            @include media-breakpoint-up(md) {
              max-height: 100%;
            }
          }
        }

        .news-item-contentwrapper {
          background-color: rgba(0, 0, 0, .7);
          //bottom: 0;
          color: #fff;
          display: flex;
          flex-direction: column;
          padding: 1.3rem;
          position: absolute;
          transition: 300ms all;
          width: 100%;
          height: 100%;
          /* @media (hover: hover) {
            @include media-breakpoint-up(md) {
              bottom: calc(-100% + 5.5rem);
              height: 100%;
            }
          }
          @media (hover: hover) {
            @include media-breakpoint-up(md) {
              @include beg-icon($beg-icon-arrow_carrot-up-double);
              &:before {
                font-size: 3rem;
                height: 3rem;
                margin: 0 auto 1.3rem auto;
                opacity: 1;
                transition: 300ms all;
              }
            }
          } */
          h2 {
            color: $white;
          }
        }
      }

      .ce_text {
        a {
          display: block;

          &:hover,
          &:focus,
          &:active,
          &:target {

            .news-item-contentwrapper {
              bottom: 0;
              transition: 300ms all;

              &::before {
                height: 0;
                opacity: 0;
                transition: 300ms all;
              }
            }
          }
        }
      }
    }
  }
}

.bike-tips {
  .container {
    .row {
      background: $gradient;

      .box-content {
        p {
          color: $white;
          @include font-size(1.5rem);
          font-family: $font-family-poppins;
          font-style: italic;
          font-weight: 600;
          margin: 0;
          padding: 0;
          text-align: center;
        }
      }
    }
  }
  .tns-controls button {
    @include media-breakpoint-down(sm) {
      top: 75px !important;
    }

  }
}

.tiny-slider {
  .tns-nav {
    bottom: 2.5rem;
  }
}

