.mod_defas_schedule {
  .formbody {
    @include make-row;
    justify-content: space-around;
    align-items: flex-end;

    .name_origin,
    .name_destination {
      position: relative;

      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }

      .autocomplete_results_container {
        padding-right: 25px;
      }
    }

    .itdDateDayMonthYear,
    .itdTime {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
    }

    .itdTime {
      @include media-breakpoint-down(sm) {
        margin-top: 1.5rem;
      }
    }

    .form-group {
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .flatpickr {
      display: block;

      input {
        text-align: center;
        border-left: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }


      .input-group {
        .btn {
          //color: $primary;
          //padding-left: 0.5rem;
          //padding-right: 0.5rem;
          //transform: none;
          //height: 100%;
          //border: $input-border-width solid $input-border-color !important;
          //border-right: 0 !important;
          //border-top-left-radius: $input-border-radius;
          //border-bottom-left-radius: $input-border-radius;

          span {
            @include visually-hidden();
          }
        }

        .datepicker {
          i {
            @include beg-icon($beg-icon-calendar);
          }


        }

        .timepicker {
          i {
            @include beg-icon($beg-icon-clock);
          }
        }
      }
    }

    .widget-submit {
      @include media-breakpoint-down(lg) {
        margin-top: 1.5rem;
      }
      //margin-bottom: 0.25rem;
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col-auto();
      }

      @include media-breakpoint-down(lg) {
        @include make-col-auto();
      }

      button {
        &.btn-lg {
          padding: 0.1rem 1rem;
        }

        &.btn-primary {
          background-color: transparent;
          border: 2px solid $secondary;
          color: $primary;

          &:hover {
            border: 2px solid $primary;
            color: $primary;
          }
        }

      }

      // button {
      //   @include clear-btn();
      //   @include beg-icon($beg-icon-search, "after");
      //   font-weight: $font-weight-bold;
      //   font-style: italic;
      //   transform: none;

      //   &:after {
      //     color: $cyan;
      //     margin-left: 0.5rem;
      //   }

      //   &:hover {
      //     color: $cyan !important;
      //     background-color: transparent !important;
      //   }
      // }
    }
  }
}

.flatpickr-calendar {
  .flatpickr-months {
    .flatpickr-monthDropdown-months {
      option {
        color: $primary;
      }
    }
  }

  .flatpickr-day:not(.flatpickr-disabled) {
    font-weight: bold;

    &.nextMonthDay,
    &.prevMonthDay {
      color: #666666;
      font-weight: lighter;
    }
  }

}

.defas-teaser-container {
  .defas-input .col_1 {
    background: white;
    i.datepicker {
        @include beg-icon($beg-icon-calendar);
    }

    i.timepicker {
        @include beg-icon($beg-icon-clock);
    }
  }
  .ce_colsetPart.col_2.last {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    position: relative;

    .ce_text.block {
      position: absolute;
      top: 28%;
      padding: 4rem;
    }
    .ce_image.block {
      margin-bottom: 0 !important;
      height: 100%;
      min-height: 50vh;
    }
  }

  .ce_hyperlink {
    margin-bottom: 2rem !important;
    font-weight: $font-weight-bold;
  }
}
