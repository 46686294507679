$icomoon-font-family: "beg-icons" !default;
$icomoon-font-path: "fonts" !default;

$beg-icon-external-link-thin: unquote('"\\e92b"');
$beg-icon-arrow-round-right: unquote('"\\e92a"');
$beg-icon-avatar: unquote('"\\e925"');
$beg-icon-paperclip: unquote('"\\e926"');
$beg-icon-checklist: unquote('"\\e927"');
$beg-icon-network: unquote('"\\e928"');
$beg-icon-calendar-xmark: unquote('"\\e929"');
$beg-icon-cycle: unquote('"\\e920"');
$beg-icon-document: unquote('"\\e921"');
$beg-icon-info: unquote('"\\e922"');
$beg-icon-pen-to-square: unquote('"\\e923"');
$beg-icon-image: unquote('"\\e924"');
$beg-icon-download-arrow: unquote('"\\e91f"');
$beg-icon-bus: unquote('"\\e91a"');
$beg-icon-calendar-check: unquote('"\\e91b"');
$beg-icon-camera-solid: unquote('"\\e91c"');
$beg-icon-cloud-sun: unquote('"\\e91d"');
$beg-icon-map: unquote('"\\e91e"');
$beg-icon-xing: unquote('"\\e914"');
$beg-icon-linkedin: unquote('"\\e915"');
$beg-icon-whatsapp: unquote('"\\e916"');
$beg-icon-envelope: unquote('"\\e917"');
$beg-icon-facebook: unquote('"\\e918"');
$beg-icon-twitter: unquote('"\\e919"');
$beg-icon-activity: unquote('"\\e912"');
$beg-icon-cart: unquote('"\\e910"');
$beg-icon-download: unquote('"\\e911"');
$beg-icon-house: unquote('"\\e90d"');
$beg-icon-minus: unquote('"\\e90e"');
$beg-icon-plus: unquote('"\\e90f"');
$beg-icon-clock: unquote('"\\e90a"');
$beg-icon-calendar: unquote('"\\e90b"');
$beg-icon-marker: unquote('"\\e90c"');
$beg-icon-easy-lang-active: unquote('"\\e909"');
$beg-icon-chat: unquote('"\\e906"');
$beg-icon-chat-bavarian: unquote('"\\e907"');
$beg-icon-easy-lang: unquote('"\\e901"');
$beg-icon-contrast-full: unquote('"\\e902"');
$beg-icon-contrast: unquote('"\\e903"');
$beg-icon-animation-play: unquote('"\\e905"');
$beg-icon-animation-stop: unquote('"\\e900"');
$beg-icon-search: unquote('"\\e904"');
$beg-icon-arrow_carrot-up-double: unquote('"\\e908"');
$beg-icon-arrow_carrot-up_alt2: unquote('"\\e750"');
$beg-icon-arrow_carrot-up: unquote('"\\e751"');
$beg-icon-arrow_carrot-right_alt2: unquote('"\\e752"');
$beg-icon-arrow_carrot-right: unquote('"\\e753"');
$beg-icon-arrow_carrot-left_alt2: unquote('"\\e754"');
$beg-icon-arrow_carrot-left: unquote('"\\e755"');
$beg-icon-arrow_carrot-down_alt2: unquote('"\\e756"');
$beg-icon-arrow_carrot-down: unquote('"\\e757"');
$beg-icon-close: unquote('"\\e913"');

