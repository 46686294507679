#bbiz-6000-kilometer-streckennetz {

  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width;
  }

  .background-l0 {
    text-align: right;
    padding-right: 9%;

    #bayern-gleis-pfeil {
      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }

    #pfeil-gross {
      width: 40%;
      position: absolute;
      bottom: 0;
      right: 9%;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  > .content {
    @include make-row();

    > .figure {

      @include media-breakpoint-up(md) {
        @include make-col-ready();
        @include make-col(5);
      }

      @include media-breakpoint-only(md) {
        margin-top: $grid-gutter-width * 2.5;
      }

      @include media-breakpoint-down(sm) {
        float: none;
        padding: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: right;

        #bayern-gleis-pfeil {

          .pfeil {
            display: none;
          }
        }
      }

      @include media-breakpoint-only(sm) {
        #bayern-gleis-pfeil {

          .gleis {
            transform: scale(.7, .7) translate(-0%, -$grid-gutter-width);
          }

        }
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);

      .h1 small {
        display: block;
      }

      .h2 span.break-xs {
        display: block;
      }

      @include media-breakpoint-up(md) {
        @include make-col(7);

        .h1 small,
        .h2 span.break-xs {
          display: inline;
        }
      }

      @include media-breakpoint-up(lg) {
        .inner {
          margin-left: -$grid-gutter-width;
          padding-top: $grid-gutter-width * 2;
        }
      }

    }
  }
}
