.mod_iso_checkout {
  .steps {
    margin-bottom: 3rem;
    a {
      text-decoration: none;
    }
    ol {
      display: flex;
      flex-direction: column;
      border-bottom: 2px solid $primary;
      padding: 1rem;
      flex-wrap: wrap;
      padding-inline-start: 55px;
      @include media-breakpoint-up(sm) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 2rem 3rem 2rem 3rem;
      }
      .active {
        font-weight: $font-weight-bold;
      }
      li::marker {
        font-size: 3rem;
        color: $primary;
      }
    }
  }
  .formbody {
    .address.billingaddress {
      .address_new {
        @include media-breakpoint-up(sm) {
          .billingaddress_salutation, .billingaddress_firstname, .billingaddress_lastname, .billingaddress_company, .billingaddress_street_2, .billingaddress_street_1, .billingaddress_postal, .billingaddress_city, .billingaddress_country, .billingaddress_email {
            width: 32%;
            margin-right: 1%;
            display: inline-block;
          }
          // .billingaddress_street_1, .billingaddress_postal, .billingaddress_city {
          //   width: 50%;
          //   display: inline-block;
          // }
        } 
      }
    }

    .billingaddress_gender legend, .shippingaddress_gender legend {
      font-size: 1.15rem;
    }

    #shippingaddress_new.address_new {
      .shippingaddress_gender.form-group {
        width: 100%;
      }

      .form-group {
        width: 32%;
        margin-right: 1%;
        display: inline-block;
      }
    }
  }

  .orderinfo {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      position: relative;
      width: 100%;
      //padding-right: 15px;
      padding-left: 15px;

      &.info_container:nth-of-type(odd) {
        clear: left;
      }

      p {
        flex: 0 0 100%;
        max-width: 100%;
      }
  }

  .notes {
    margin-bottom: 4rem !important;
    margin-top: 2rem;
  }

  .review {
      .image_container img {
        height: auto;
        object-fit: cover;
        max-height: 150px;
        width: 100%;
      }

      .info_container {
        background-color: $light;
        padding: 2rem;
        flex: 0 0 48%;
        max-width: 48%;
        margin-top: 2%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-right: 0;
          }
        &.billing_address {
          // display: flex;
          // flex-direction: row;
          // justify-content: space-between;
        }
        h3 {
          // margin-top: 1.5rem;
        }
        a.edit {
          text-decoration: none !important;
          //order: 3;
          font-weight: $font-weight-bold;
          margin-top: 1.5rem;
          &::before {
            font-family: "beg-icons";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            content: "\e755";
          }
          &:hover {
            text-decoration: $font-weight-bold;
          }
        }
      } 
      .datenschutz {
          margin-top: 2rem;
          margin-bottom: 0rem;
        }
    }

    .shipping.shippingmethod, .address.shippingaddress {
      .shippingmethod, .shippingaddress {
        .form-label {
          visibility: hidden;
          height: 1px;
        }
      }
    }

    .shippingaddress {
      h2 {
        margin-top: 4rem;
      }
    }

    .orderproducts {
      .cart-table {
        .header, .subtotal, .total, .foot_1 {
          font-weight: $font-weight-bold;
        }
        .header {
          border-bottom: 2px solid $primary;
        }
        .table-striped {
          background-color: $white !important;
        }
        a.remove {
         font-size: 0;
          text-decoration: none !important;
          &:after {
            font-family: "beg-icons";
            speak: none;
            font-size: 2rem;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            content: "\e913";
          }
        }
      }
      .table {  
        --bs-table-striped-bg: $white; 
      }
    
      .table > :not(:first-child) {
        border-top: 2px solid $primary;
      }
    }

    .submit_container {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
    }

    .billingaddress_gender, .shippingaddress_gender {
      .form-check {
        display: inline-block;
        margin-right: 1rem;
      }
    }
}

