.leisure_tips_list {
 .ce_colsetStart {
   .col_1 {
     z-index: 100;
     padding-right: 2rem;
   }
 }
}
.card.card-leisure-tips {
  border: none;

  a {
    text-decoration: none;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
    font-size: $font-size-sm;
  }

  @include media-breakpoint-down(sm) {
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.leisuretips-details {
  .privacy-center-protected-code {
    max-height: 32rem;
    overflow: hidden;
    .allow-protected-code {
      left: 31%;
    }
    @include media-breakpoint-up(md) {
      .allow-protected-code {
        left: 47%;
      }
    }
    .poster {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.leisuretips {
  .ce_privacy_center_html.osm-container {


    .privacy-center-protected-code {
      height: 55vh;

      .poster {
        height: 80%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .allow-protected-code {
          left: 31%;
          top: 40%;
        }
      }
    }

    @include media-breakpoint-up(md) {
      height: 100%;
      left: 0;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: unset;
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      .privacy-center-protected-code {
        position: sticky;
        width: 50%;
        margin-left: 50%;
        top: 0;
        height: 100vh !important;
        .poster {
          .allow-protected-code {
            left: 43%;
            top: 40%;
          }
        }
      }
    }
  }
}
