#bbiz-122-mio-zugkilometer {

  @include media-breakpoint-up(lg) {
    padding-bottom: $grid-gutter-width * 4;
  }

  .background-l0 {
    text-align: center;
    padding-right: 9%;

    @include media-breakpoint-up(xl) {
      margin-top: -40px;
    }

    #globus-arrow {
      width: 40%;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  > .content {
    @include make-row();

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    > .figure {
      @include make-col-ready();
      @include make-col(12);
      text-align: center;

      #globus {
        width: 35%;

        .globus {
          opacity: 1;
        }
      }

      @include media-breakpoint-up(md) {
        @include make-col(3);
        text-align: unset;

        #globus {
          width: unset;

          .globus {
            opacity: .4;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(9);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(8);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(7);
      }
    }
  }
}
