.online-magazin {
  .ce_text {
    display: flex;
    align-items: center;
    height: 100%;
  }
 
    .ipad-magazin {
      background-color: #efefef;
      border-radius: 25px;
      border: 18px solid $black;
      box-shadow: 0px 0px 0px 2px #ffffff, 12px 12px 42px -11px #000000;
      height: 260px;

      @include media-breakpoint-down(sm) {
        margin-right: 0px;
        height: 450px;
        border-radius: 15px;
      }

      @include media-breakpoint-up(sm) {
        margin-right: 40px;
        height: 220px;
        border: 12px solid $black;
        border-radius: 15px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 50px;
        height: 400px;
        border: 18px solid $black;
        border-radius: 25px;
      }
  }
}