body.tickets-network {

    .network-map {
        svg {
            padding-left: 25px;
            padding-right: 25px;
            @include media-breakpoint-up(lg) {
                width: 60% !important;
            }
        }
    }
    

    .my-5 {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }
    .overview {
        margin-top: -60px;
        .ce_linkteaser {
            background-color: $primary;
            position: relative;
            width: 100%;
            margin-bottom: 1.5rem !important;
            @include media-breakpoint-down(lg) {
                max-height: 14rem;
            }
            @include media-breakpoint-up(lg) {
                height: 225px;
            }

            &:hover {
                cursor: pointer;
                background-color: $secondary;
            }
            .inside {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 100%;
                height: 100%;
                padding: 4rem 1rem 4rem 1rem;
                overflow: hidden;
                color: $white;
                @include media-breakpoint-down(lg) {
                    max-height: 14rem;
                }
                @include media-breakpoint-up(lg) {
                    height: 225px;
                }
                //background-color: $primary;
                    &:hover {
                        cursor: pointer;
                        //background-color: $secondary;
                    }
                    &:hover .content {
                        top: -100%;
                        transition: top .5s;
                    }
                .image_container {
                    padding: 0 50px;
                    width: 100%;
                    height: 100%;
                    img, svg {
                        width: 100%;
                        object-fit: contain;
                    }
                }
                .content {
                    transition: top .5s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    text-align: center;
                    background-color: $primary;
                    .image {
                        padding: 15px;
                    }
                    .partner-bold {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }
}

// .mod_huhlist.tl_partner {
//     .items {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         .item {
//             position: relative;
//             width: 100%;
//             padding-right: 15px;
//             padding-left: 15px;
//             margin-bottom: 1rem;
//             min-height: 275px;

//             @media (min-width: 768px){
//               flex: 0 0 25%;
//               max-width: 25%;
//             }
//             .inside {
//                 position: relative;
//                 width: 100%;
//                 height: 100%;
//                 overflow: hidden;
//                 background-color: #fff;
//                     &:hover {
//                         cursor: pointer;
//                     }
//                     &:hover .company {
//                         top: -100%;
//                         transition: top .5s;
//                     }
//                 .content {
//                     padding: 0 15px;
//                 }
//                 .company {
//                     transition: top .5s;
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     width: 100%;
//                     height: 100%;
//                     z-index: 9;
//                     text-align: center;
//                     background-color: #fff;
//                     .image {
//                         padding: 15px;
//                     }
//                 }
//             }
//         }
//     }
// }
