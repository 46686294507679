body.auskunft, body.bayern-fahrplan {
  font-size: $font-size-base;
  letter-spacing: unset;
  #main {
    padding-top: 0 !important;
    .inside.container {
      max-width: unset;
      padding: 0;
      // bayern-fahrplan
      #root {
        position: relative;
        z-index: 50;

        > div {
          position: relative;
          height: 80vh;
          z-index: 0;
        }

        //h1#formularTitle {
        //  color: $yellow;
        //}

        //div[role="combobox"] > input,
        //div[role="combobox"] > input::placeholder,
        //#viaInput input{
        //  color: white !important;
        //
        //}
        legend {
          float: none;
        }

        h3 {
          color: inherit;
          font-style: inherit;
          text-transform: inherit;
        }
      }
      .ce_privacy_center_html {
        margin-bottom: 0 !important;
      }
    }
  }

  //&.high-contrast {
  //  #root {
  //    div[role="combobox"] > input,
  //    div[role="combobox"] > input::placeholder,
  //    #viaInput input,
  //    label{
  //      color: white !important;
  //
  //    }
  //    legend {
  //      float: none;
  //    }
  //  }
  //}
}


