#osmConsentBox {
  display: flex;
  width: 100%;
  height: 87dvh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  .content {
    background: var(--bs-secondary-bg);
    padding: 4rem;
    .optBtns {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }
}
