.ce_huh_hero {
  margin-bottom: 0rem;
  .text-container {
    .diamond-hero-bg {
      background-image: url(../../../common/img/raute/raute-verlauf.svg);

      .hero-sub-title {
        @include media-breakpoint-up(sm) {
          width: 70%;
        }

        h1,
        .h1 {
          color: $yellow !important;
          span {
            &:first-child {
              margin-left: 0rem;
            }
            &:nth-child(2) {
              margin-left: 2rem;
              color: $white !important;
            }
          }
        }

        p:not(.h1) {
          color: $white;
        }
      }

      @include media-breakpoint-only(xs) {
        background-image: none !important;
      }
    }
  }
}
