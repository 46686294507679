body.leisuretips > #wrapper {

  #main > .inside > .mod_article:first-child .ce_colsetPart.last .mod_huhlist {

    @include col-full();
    margin-bottom: $spacer*3;

    div[id^="map_canvas_"] {
      padding-bottom: 62.5%;
    }
  }

  @include media-breakpoint-up(md) {
    #main {
      padding-top: 0;

      > .inside > .mod_article:first-child {

        margin-bottom: $spacer*3;

        .ce_colsetStart:first-child,
        .ce_colsetStart.first {
          .ce_beg_headline {
            margin-top: 3.5rem;
          }
        }

        .ce_colsetPart:last-child,
        .ce_colsetPart.last {
          position: relative;

          .mod_huhlist {
            width: 100vw;
            max-width: unset;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin-left: -50vw;
            margin-right: -50vw;
            height: 100%;

            > .wrapper {
              width: 50%;
              margin-left: 50%;
              position: sticky;
              top: 0;
              height: 100vh;

              .map {
                width: 100%;
                height: 100%;

                div[id^="map_canvas_"] {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    #main > .inside > .mod_article:first-child .ce_colsetPart > .mod_huhlist {
      top: 0;
      height: 100vh;
    }
  }
}

body.leisuretips-details {
  .news-detail-container {
    .reader-navigation {
      border-bottom: 0px;
      margin-top: 2rem;
      &.article-navigation {
        a.nav-link {
          text-decoration: none !important;
          transform: skew(-10deg);
          text-transform: uppercase;
          background-color: #FFD53B;
          color: #005FA3;
          position: relative;
          border: 0;
          border-radius: 0;
          padding: 0.3rem 2rem;
          font-size: 1.125rem;
          font-weight: 800;
          line-height: 1.5;
          text-align: center;
          vertical-align: middle;
          transition: all 0.1s ease-out;
          font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          &:hover {
            background-color: $primary;
            color: $white;
            text-decoration: none !important;
          }
        }
      }   
    }
  }
}