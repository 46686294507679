#bbiz-bayern-tickets {

  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 2.5;
  }

  .background-l0 {
    #bbiz-bayern-tickets-background {
      position: absolute;
      bottom: 5%;
      width: 100%;
      height: 100%;

      .pfeil {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  > .content {
    @include make-row();

    .figure-left {
      @include make-col-ready();
      @include make-col(12);
      text-align: center;

      #bbiz-bayern-tickets-left {
        width: 35%;
        margin-top: 0;
      }

      @include media-breakpoint-up(md) {
        @include make-col(3);
        text-align: right;

        #bbiz-bayern-tickets-left {
          width: 70%;
          margin-top: 40px;
        }
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      .content-box {
        @include make-row();

        > .content {
          @include make-col-ready();
          @include make-col(12);
          @include media-breakpoint-up(lg) {
            @include make-col(11);
          }
          @include media-breakpoint-up(xl) {
            @include make-col(10);
          }
        }

      }
    }

    .figure-right {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        @include make-col-ready();
        @include make-col(3);
      }
    }
  }
}
