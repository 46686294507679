html {
    overflow-x: hidden;
    @include media-breakpoint-up(sm) {
        overflow-x: auto;
        }
}

.ce_huh_hero.superjob {
    .hero-container {
        .image_container {
            @include media-breakpoint-down(sm) {
            visibility: hidden;
            }
        }
    }
}

.display-box {
    // left: 19%;
    // top: 12%;
    //transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    position: absolute;
    width: 450px;
    left: 0;
    z-index: 1;
    height: 100%;
    background-image: url(../../img/superjob/bahnland-superjobs-schild.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(sm) {
        width: 100%;
        height: 44%;
        background-image: url(../../img/superjob/beg_superjob_header_mobile_01.jpg);
        background-size: cover;
    }

    @media (min-width: 2200px) {
    width: 100%;
    left: 0;
    height: 100%;
    background-position: top left;
    justify-content: flex-start;
    }


    // @include media-breakpoint-up(md) {
    //     font-size: 2.1vw;
    // }

    // @include media-breakpoint-up(sm) {
    //     left: 8%;
    //     top: 40%;
    //     transform: translate(-50%, -50%);
    // }

    // @include media-breakpoint-up(md) {
    //     left: 18%;
    //     top: 40%;
    //     transform: translate(-50%, -50%);
    // }

    // @include media-breakpoint-up(lg) {
    //     left: 21%;
    //     top: 39%;
    //     transform: translate(-50%, -50%);
    // }

    // @include media-breakpoint-up(xl) {
    //     left: 17%;
    //     width: 380px;
    //     height: 50px;
    //     background: transparent;
    //     top: 36%;
    // }


    .display-name {
        font-size: 1.8vw;
        font-weight: $font-weight-bold;
        font-style: italic;
        color: $white;
        position: absolute;
        top: 33.5%;
        text-overflow: ellipsis;
        white-space: nowrap; /* Verhindert Zeilenumbruch */
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            font-size: 3vh;
            top: 37%;
        }

        @media (min-width: 2200px) {
            left: 5%;
        }
        // .text-cursor {
        //     display: inline-block;
        //     width: 1ch;
        //     background-color: transparent;
        //     animation: blink 1s steps(1) infinite;
        // }

        // @keyframes blink {
        //     50% {
        //         opacity: 0;
        //     }
        // }
    }
}

.name-field {
    .name-placeholder {
        background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, 0) 70%, rgba($yellow, 1) 70%, rgba($yellow, 1) 100%);
        display: inline;
        padding: 0 .1em;
    }
}

// Arbeitgeber Liste

.tl_news.superjob-companies .news-item .details figure {
    padding: 2rem 3rem;
    margin-bottom: 1.5rem !important;
    background: $grey-light;
}

// Hero

.ce_colsetStart.superjob {
    .container {
        @include media-breakpoint-down(sm) {
            position: relative;
        }
    }
}