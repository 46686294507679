#bbiz-finanzmittel {

  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 3;
  }

  > .content-wrapper {

    > .content {
      @include make-row();

      @include media-breakpoint-up(md) {
        height: 100%;
      }

      .figure-left,
      .figure-right {
        @include media-breakpoint-up(md) {
          @include make-col-ready();
          @include make-col(1);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(2);
        }
      }

      .figure-left {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }

        svg {
          @include media-breakpoint-up(xl) {
            margin-right: -100px;
            margin-top: -90px;
            transform: scale(0.8);
          }
          @include media-breakpoint-only(lg) {
            margin-right: -50px;
            margin-top: -20px;
            transform: scale(1.25);
          }
          @include media-breakpoint-only(md) {
            margin-right: -40px;
            margin-top: 0px;
            transform: scale(1.5);
          }
        }
      }

      .figure-middle {
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .figure-right {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
          svg {
            transform: scale(8);
            margin-top: 390px;
            margin-left: -15px;
          }
        }
        @include media-breakpoint-up(lg) {
          svg {
            transform: scale(9);
            margin-top: 320px;
          }
        }
        @include media-breakpoint-up(xl) {
          svg {
            transform: scale(3);
            margin-top: 260px;
          }
        }
      }

      > .text {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(md) {
          @include make-col(8);
          .ce_text {
            width: 75%;
          }
        }
        @include media-breakpoint-up(xl) {
          @include make-col(7);
        }
      }
    }
  }
}
