#bbiz-vielfalt {
  .background-l0 {
    width: 50%;
    right: 0;

    #bbiz-vielfalt-background {
      .netz {
        opacity: .5;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 75%;
      top: 10%;

      #bbiz-vielfalt-background .pfeil {
        display: none;
      }
    }
  }

  > .content {
    @include make-row();

    > .figure {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        @include make-col-ready();
        @include make-col(3);

        #bbiz-vielfalt-left {
          transform: translate(0, 15em);
        }
      }
      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
      @include media-breakpoint-up(xl) {
        #bbiz-vielfalt-left {
          transform: translate(-$grid-gutter-width* 2.5, 15em);
        }
      }


    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(9);
      }
    }
  }
}
