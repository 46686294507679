body {
  &.high-contrast {

    // Navigation 

    .nav-link.active,
    .nav-link.submenu.trail {
      color: $yellow !important;
      font-weight: $font-weight-bold;
    }
    
    #header,
    .mm-menu,
    .sticky-nav {
      background: $primary !important;
      --mm-color-background: #{$primary} !important;

      .mm-listitem_selected a,
      a:focus {
        background: $primary;
        //border: 1px solid $white;
      }

      .mm-panel {
        background-color: $primary !important;
      }

      .navbar {
        .level_2 {
          background-color: $primary !important;
          background: none;
        }
      }

      .header-nav-tools {
        .d-none.d-lg-flex.justify-content-end * {
          font-weight: 600;
        }
      }
    }

    .header-top,
    .sticky-nav {
      .navbar {
        .level_2 {
          &:before {
            background: $primary !important;
          }
        }
      }
    }

    #footer {
      .footer-app-blue {
        color: $primary;
      }

      .footer-app {
        .btn-primary {
          &:hover {
            background-color: $primary !important;
            color: $white !important;
          }
        }
      }
    }

    .form-group {

      input,
      select {
        border-color: $primary;
      }
    }

    .form-select {
      border: 1px solid $primary !important;
    }


    .formhybrid_defas_schedule {
      .btn-primary {
        color: $primary;
      }

      .widget-submit button:after {
        color: $primary;
      }

      form .input-group .btn {
        border: 1px solid $primary;
      }

      .widget-submit {
        button {
          &.btn-primary {
            background-color: transparent;
            border: 2px solid $primary;
          }
        }
      }
    }

    .leisure-destinations {
      .link-with-icon-blue {
        &:after {
          color: $white !important;
        }
      }
    }

    .mod_huhlist.huh-list.tl_news {
      .link-with-icon-blue {

        &:before,
        &:after {
          color: $primary;
        }
      }
    }

    .link-with-icon-blue {

      &:before,
      &:after {
        color: $primary;
      }
    }

    .pagination-wrapper {
      .page-item {
        a {

          &:hover,
          &:focus {
            color: $white;
            background: $primary;
          }
        }
      }
    }

    .alert-info {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }

    .tl_partner {

      .item,
      .item:nth-child(9n+5) {
        background-color: $white !important;
        border: 1px solid $primary;
      }
    }

    .col-full.bg-gradient,
    .ce_text.bg-gradient {
      background-color: $primary !important;
      background-image: none !important;
    }

    .col-full.bg-blue {
      background-color: $white !important;
      border-bottom: 2px solid $primary;
    }

    .answer {
      background-color: $primary !important;
      color: $white;
    }

    .item:nth-child(5n + 1) {
      background: $primary;
    }

    .item:nth-child(6n + 2) {
      background: $primary;
    }

    .item:nth-child(7n + 3) {
      background: $primary;
    }

    .item:nth-child(8n + 4) {
      background: $primary;
    }

    .item:nth-child(9n + 5) {
      background: $primary;
    }

    .item {
      background-color: $primary;
    }

    .ce_huh_hero {
      .diamond-hero-bg {
        background-image: url(../../../common/img/raute/raute-blau.svg);
      }
    }

    .footer-hero {
      .ce_huh_hero {
        background: $primary !important;

        &::before {
          background-image: url(../../../common/img/block/block-footer-blue.svg) !important;
        }
      }
    }

    #hero {
      @include media-breakpoint-only(xs) {
        background: $primary;
      }

      .hero-sub-title {
        background: $primary;
      }
    }

    .bg-hero {
      display: none !important;
    }

    .bg-hero.contrast {
      @include media-breakpoint-up(lg) {
        display: block !important;
      }
    }

    .bg-hero.contrast-tablet {
      @include media-breakpoint-only(md) {
        display: block !important;
      }
    }

    .bg-hero.contrast-mobile {
      @include media-breakpoint-down(sm) {
        display: block !important;
      }
    }

    .mod_filter {
      .input-group-text {
        border: 1px solid $primary !important;
      }
    }

    #darumbahn .text-yellow {
      color: $white !important;
    }

    .btn-primary,
    .ajax-pagination a.next,
    .submit_container input[type=submit] {
      background-color: $primary;
      color: $white;
    }

    // Tickets

    .additionalText {
      span {
        color: $black !important;
      }
    }

    .frequent-driver,
    .wheelchair {
      .text-container {
        .btn-primary {
          background-color: $primary;
          color: $white;
        }
      }
    }

    //

    .btn-primary a {
      color: $white;
    }

    .bike.bg-gradient,
    .group.bg-gradient {
      background-color: $primary;
      color: $white;
    }

    .bg-gradient {
      background-image: var(--bs-primary) !important;
      background-color: $primary;
    }

    .bg-gradient,
    .ce_huh_media_text,
    .footer-hero {
      .text-yellow {
        color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
      }

      button.btn.btn-primary,
      a.btn.btn-primary {
        background-color: $white !important;
        color: $primary !important;

        &:hover {
          background-color: $yellow !important;
          color: $primary !important;
        }
      }

      a:hover {
        color: $yellow;
      }

      a.text-white {
        &:hover {
          color: $yellow !important;
        }
      }
    }

    .tiny-slider {
      .tns-controls {
        button {
          background: #fff !important;
          color: $primary !important;
        }
      }
    }

    &.page-gradient {
      #container {
        background: $primary;

        .text-yellow {
          color: $yellow !important;
        }
      }
    }

    &.page-waypoints {
      svg {
        path {
          fill-opacity: 1;
        }

        &#people-left,
        &#bbiz-bayern-tickets-left,
        &#bbiz-app-left,
        &#wettbewerb-pokal,
        &#bbiz-fahrgaeste-left,
        &#bbiz-freistaat-left,
        g.gleis,
        g.text-8x,
        g.train,
        g.Uhr,
        g.man,
        g.man-bottom,
        g.clipboard,
        g.wegweiser {
          path {
            fill: #fff;
          }
        }

        g.pfeil {
          path {
            fill: transparent;
          }
        }

        .globus {
          display: none;
        }
      }

      #bayern-gleis-pfeil {

        .bayern,
        .pfeil {
          display: none;
        }
      }

      .pfeil {
        path {
          stroke: #ffffff;
        }

        polygon {
          fill: #ffffff;
        }
      }

      .background {
        svg {
          display: none;
        }
      }
    }

    .bike-tips {
      .container {
        .row {
          background: $primary;
        }
      }
    }

    .magazine-slider-item {
      .content {
        background: $black !important;
      }

      label {
        color: $white !important;
      }

      .form-control {
        border: 1px solid $white !important;
      }
    }

    #bahnland-bayern-magazin input[type=number],
    #bahnland-bayern-magazin .cart-qty input[type=number]:focus {
      color: $white !important;
      background-color: $black;
    }

    #bahnland-bayern-magazin {
      button[type=submit]:hover {
        background-color: #fff !important;
        color: #000 !important;
      }

      .bg-primary {
        .btn-primary {
          background-color: #fff !important;
          color: $primary !important;

          &:hover {
            background-color: $yellow !important;
          }
        }
      }
    }

    .bg-blue {
      .tiny-slider {
        .quantity_container {
          label {
            color: $white;
          }
        }
      }
    }

    //quick links/price box > tickets and trip

    #quicklinks {
      .bg-secondary {
        background-color: $primary !important;
        border-left: 2px solid $white;
        border-right: 2px solid $white;
      }
    }

    .price-box.bg-secondary,
    .ce_huh_media_text.bg-gradient {
      background-color: $primary !important;
    }

    .ce_huh_media_text .row {
      background-color: $primary !important;
    }

    .ce_huh_media_text .box-content {

      .text-element,
      p,
      a,
      h1,
      h2,
      h3,
      h4 {
        color: $white;
      }
    }

    .ce_huh_media_text .headline-wrapper {
      h2 {
        color: $white;
      }
    }

    // Shop > Checkout > Review
    .mod_iso_checkout .review .info_container {
      background-color: $white;
      border: 2px solid $primary;
    }

    .frequent-driver,
    .wheelchair {

      .text,
      .text-container>div {
        background-color: $white;
        padding: 2rem;

        h1,
        h2 {
          color: $primary !important;
        }

        p,
        .lead {
          color: $black !important;
          text-shadow: 0px 0px 0px #000000;
        }

        h2 {
          text-shadow: 0px 0px 0px #000000;
        }
      }
    }

    #bahnland-bayern-karten-plaene,
    #bahnland-bayern-ausflugstipps,
    #stationsflyer,
    #halte-strecken {
      .ce_colsetStart.g-0 {
        border: 2px solid $primary;
      }
    }

    #bahnland-bayern-news,
    #latest-publications {
      .news-slider-item {
        border: 1px solid $white;
      }

      label {
        color: $white !important;
      }

      button[data-controls="next"],
      button[data-controls="prev"] {
        background: $white !important;
        color: $primary !important;
      }

      .tns-item {
        background: $primary !important;

        h4,
        small {
          color: $white !important;
        }

        button {
          border-color: $white !important;

          &:before {
            color: $white !important;
          }
        }

        input[type="number"] {
          border-color: $white !important;
          color: $white !important;
          background-color: $primary;
        }
      }
    }

    .tl_ticket {
      .item {
        background-color: transparent;

        .price-box {
          background-color: $primary !important;
        }
      }
    }

    table {
      border: 3px solid $primary;
    }

    .table-striped {
      &>tbody>tr {
        background-color: $white;
      }
    }

    .mod_filter {
      .input-group {
        select {
          padding-left: 1rem;
        }
      }
    }

    .mod_filecredits_list {
      .item {
        background-color: $white !important;
      }
    }

    //modal stationsdatenbank
    .modal-mapublisher {
      .modal-footer {
        .btn.btn-secondary {
          color: $white !important;
          background-color: $primary;
        }
      }
    }

    .ce_text.block ul li::before,
    .ce_list ul li::before {
      color: $primary;
    }

    .alert-warning,
    .mod_status_messages .msg-info {
      color: $black;
      background-color: $white;
      border-color: $primary;
    }

    // #bahnland_navigation_top_mobile.mm-menu_popup {
    //   .mm-panels .level_1, .mm-panels .level_2  {
    //     a, .mm-listitem_selected {
    //       border: 1px solid rgba(255,255,255,1);
    //     }
    //   }
    // }

    // Ausflugtipps Rubrik Detailseite

    .news-detail-container .text-blue {
      color: $black !important;
    }

    .news-detail-container .reader-navigation.article-navigation a.nav-link {
      background-color: $primary !important;
      color: $white !important;
    }

    // Akkordeon

    .accordion-button.collapsed {
      &:after {
        color: $primary !important;
      }
    }

    // MOBY
    .ce_linkteaser.moby.bg-secondary {
      background-color: $primary;
    }

    .btn-container {
      button {
        background-color: $primary !important;
        color: $white !important;
      }
    }

    .align-items-center.bg-gradient {
      background-color: $primary !important;
    }

    // Einfache Sprache

    #header .navbar-tools .easy-lang-link a {
      background-color: transparent !important;
      color: $white !important;
    }

    .app-icon-container {
      .item {
        background-color: white;
      }
    }

    .mm-menu_keyboardfocus a:focus {
      background: white;
      color: $primary !important;
    }

  }
}