#bbiz-1060-stationen {
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 3;
  }

  .background-l0 {
    #id-1060-stationen-background {

      width: 120%;

      @include media-breakpoint-down(sm) {
        width: 186%;
        margin-left: -43%;
        margin-top: 17%;

        .rail-left {
          transform: translate(15%, 0);
        }


        .train {
          transform: translate(-10%, 0);
        }

        .pfeil {
          display: none;
        }
      }

      @include media-breakpoint-only(md) {
        width: 230%;
        transform: translate(-25%, -17%);

        .rail-left {
          transform: translate(6%, 0);
        }

        .rail-right {
          transform: translate(2%, 0);
        }

        .train {
          transform: translate(-10%, 0);
        }
      }

      @include media-breakpoint-only(lg) {
        width: 230%;
        transform: translate(-27%, -18%);

        .rail-left {
          transform: translate(6%, 0);
        }
      }

      @include media-breakpoint-up(xl) {
        transform: translate(-6%, -17%)
      }

      .rail-left {
        opacity: 0.2;
      }

      .rail-right {
        opacity: 0.4;
      }

      .haus {
        opacity: 0.6;
      }
    }
  }

  > .content-wrapper {
    @include make-container();
    max-width: map-get($container-max-widths, xxl);

    > .content {
      @include make-row();

     > .figure-left {
        @include make-col-ready();
        @include make-col(7);
        @include media-breakpoint-up(md) {
          @include make-col(3);
        }
        @include media-breakpoint-up(xl) {
          #id-1060-stationen-left {
            margin-left: -$grid-gutter-width * 2.5
          }
        }

      }

      .headline {
        @include make-row();

        .headline-left {
          @include make-col-ready();
          @include make-col(12);
          @include media-breakpoint-up(lg) {
            @include make-col(8);
          }
        }

        .figure {
          display: none;
          @include media-breakpoint-up(lg) {
            display: block;
            @include make-col-ready();
            @include make-col(4);
            #id-1060-stationen-headline {
              width: 75%;
              transform: translate(0, 48px);
            }
          }
          @include media-breakpoint-up(xl) {
            #id-1060-stationen-headline {
              width: 60%;
              transform: translate(0, 48px);
            }
          }
        }
      }

     > .text {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
      }

      .figure-right {
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
          @include make-col-ready();
          @include make-col(3);
        }

        #id-1060-stationen-right {

          @include media-breakpoint-only(md) {
            width: 85%;
            margin-left: -$grid-gutter-width;
            margin-top: -50px;
          }

          @include media-breakpoint-only(lg) {
            width: 60%;
            margin-left: $grid-gutter-width;
          }

          @include media-breakpoint-up(xl) {
            width: 75%;
            margin-top: -$grid-gutter-width;
            margin-left: $grid-gutter-width;
          }
        }
      }
    }
  }
}
