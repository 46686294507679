#root-defas > .defas-container {

  margin-bottom: 2rem;

  .datepicker-form.flatpickr-input {
    width: 100%;
  }

  .defas-form {
    .MuiFormGroup-root {
      margin-bottom: 0.5rem;
    }

    .MuiAutocomplete-root, .date-picker-container {
      margin-bottom: 1rem;
    }

    .MuiFormGroup-root[role="radiogroup"] {
      display: flex;
      justify-content: space-around;
    }

    .MuiAutocomplete-root fieldset {
      border-color: #009FE3;
    }

    .form-label {
      margin-bottom: 0;
    }
  }

  .flatpickr-ctn {

    display: flex;
    align-items: center;

    .datepicker-form {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .defas-form-icon-container {
      width: auto;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      border-left: 0;
    }
  }
}
