:root {
  --huh-autocomplete-clr-text: black;
  --huh-autocomplete-clr-bg: white;
  --huh-autocomplete-clr-bg-hover: whitesmoke;
  --huh-autocomplete-clr-border: var(--bs-primary, var(--huh-autocomplete-clr-text, black));

  --huh-autocomplete-result-color: var(--huh-autocomplete-clr-text);
  --huh-autocomplete-result-bg: var(--huh-autocomplete-clr-bg);
  --huh-autocomplete-result-bg-hover: var(--huh-autocomplete-clr-bg-hover);
  --huh-autocomplete-result-border-color: transparent;
  --huh-autocomplete-result-border-color-hover: var(--huh-autocomplete-clr-border);

  --huh-autocomplete-highlighted-color: var(--huh-autocomplete-clr-text);
  --huh-autocomplete-highlighted-bg: var(--huh-autocomplete-clr-bg);
  --huh-autocomplete-highlighted-border-color: transparent;

  --huh-autocomplete-selected-color: var(--huh-autocomplete-clr-text);
  --huh-autocomplete-selected-bg: var(--huh-autocomplete-clr-bg);
  --huh-autocomplete-selected-border-color: var(--huh-autocomplete-clr-border);
}


.autoComplete_wrapper {
  position: relative;

  > ul {
    background-color: #fff;
    border: 1px solid rgba(33, 33, 33, .1);
    border-radius: 4px;
    left: 0;
    margin: .5rem 0 0;
    max-height: 226px;
    min-width: 325px;
    outline: none;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1000;

    > li {
      //background-color: #fff;
      border-radius: 3px;
      color: #212121;
      font-size: 16px;
      list-style: none;
      overflow: hidden;
      padding: 10px 20px;
      text-align: left;
      text-overflow: ellipsis;
      -webkit-transition: all .1s ease-in-out;
      transition: all .1s ease-in-out;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      white-space: nowrap;

      &:hover, &:focus {
        background-color: rgba(0, 0, 0, .1);
        cursor: pointer;
      }

    }
  }
}


.autoComplete_highlighted {
  opacity: 1;
  font-weight: bold;
  color: var(--huh-autocomplete-highlighted-color, black);
  background-color: var(--huh-autocomplete-highlighted-bg, white);

  &::selection {
    color: transparent;
    background-color: transparent;
  }
}

.autoComplete_selected {
  border-inline: 2px solid var(--huh-autocomplete-selected-border-color, black);
  background-color: rgba(0, 0, 0, .1);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .autoComplete_result:first-child {
    border-radius: 1rem 1rem 0 0;
  }

  .autoComplete_result:last-child {
    border-radius: 0 0 1rem 1rem;
  }

  .autoComplete_result:only-child {
    border-radius: 1rem;
  }
}