@include headline-styles(1) {
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}

@include headline-styles(2) {
  text-transform: uppercase;
  margin-bottom: 2rem;
}

@include headline-styles(3) {
  text-transform: uppercase;
}
@include headline-styles(4) {
  font-weight: $font-weight-medium;
  text-transform: none;
}

@include headline-styles(5) {
  text-transform: uppercase;
}
