.mod_filter {
  .form-group {
    select {
      //color: $primary;
      appearance: auto;
      margin: 2rem 0;
      width: 40%;
      cursor: pointer;
      padding: 0.8rem 0;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}
