body:not(.ie) {
  .wp-section {
    &.appear,
    .appear {
      .no-touchevents.cssanimations &,
      .no-touch.cssanimations & {
        opacity: 0;
      }
    }
  }
}

.page-gradient {
  #container {
    background: $gradient-reverse;
  }
}

.wp-section {
  position: relative;
  padding-bottom: $grid-gutter-width * 2;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .content {
    z-index: 1;
    position: relative;
  }

  &.col-full {
    margin-bottom: 0;
  }

  + .wp-section {
    @include media-breakpoint-up(md) {
      padding-top: $grid-gutter-width;
    }
  }

  .text {
    p:last-child {
      margin-bottom: 0;
    }

    .btn {
      margin-top: 10px;
    }
  }

  .ce_text {
    margin-bottom: 0 !important;
    .text {
      width: auto !important;
      padding: 0;
    }
  }

  h1, h2, h3, h4, h5, h6, p, p a {
    color: $white;
  }

  .h1 {
    @include font-size(3.3rem);
    margin-bottom: 1rem;
    small {
      font-size: 50%;
    }
  }

  .h2 {
    @include font-size(2.1rem);
  }
}

.page-waypoints {
  main#main {
    overflow: hidden;
  }
  .ce_beg_headline h1 span {
    color: $white !important;
  }

  .badge-appstore, .badge-playstore {
    text-decoration: none !important;
    margin-right: 1rem;
    display: inline-block;
    svg {
      height: 5rem;
    }
  }

  .badge-appstore svg {width: 11rem;}
  .badge-playstore svg {width: 12rem;}

}
