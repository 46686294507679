.phonebox {
    svg {
        vertical-align: middle;
        width: 100%;
        height: 100%;
        padding: 2.5rem;

        @include media-breakpoint-up(md) {
            vertical-align: middle;
            width: auto;
            height: 100%;
            padding: 4.5rem;
           }
    }
}

body.trip {

    // .ce_beg_headline.has-h1-style {
    //     padding-top: 4.5rem;
    //     &.barrier-free{
    //         color: $white !important;
    //         &h2{
    //             color: $white !important;
    //             &.headline-indented.text-secondary{
    //                 color: $white !important; 
    //             }
    //     }
    // }}

    .btn.btn-primary:not(#ctrl_submit_182) {
            margin-top: 2rem;
        }
    

    //.wheelchair {
    //    align-items: center;
    //    background: url('/files/media/bahnland-bayern/vor-der-fahrt/bahnland-bayern-vor-der-fahrt-barrierefreiheit.jpg') bottom left no-repeat #ccc;
    //    display: flex;
    //    height: 100vh;
    //    max-height: 800px;
    //    background-size: 100%;
    //    transition: 1s background-size;
    //    &:hover {
    //        background-size: 115%;
    //        transition: 1s background-size;
    //    }
    //    &[class*=ce_].has-h1-style h2:first-of-type, h2{
    //        color: $white !important;
    //    }
    //    &h2, .h2{
    //        color: $white !important;
    //        &.headline-indented.text-secondary{
    //            color: $white !important;
    //        }
    //    }
    //    p.lead{
    //        text-shadow: 1px 1px 10px #000000
    //    }
    //}
}

.hero-block-right-wrapper {
    margin-left: -1rem;
    margin-right: -1rem;
    background-color: $primary;
}

.wheelchair {
    .hero-block-right {
        p.lead{ 
            //text-shadow: 1px 1px 20px #000000;
            color: $black !important;

            @include media-breakpoint-down(sm) {
                color: $white !important;
                width: 100%;
            }
        }
        h2{
            text-shadow: 5px 5px 30px #000000;
            @include media-breakpoint-down(sm) {
                text-shadow: 0px 0px 0px #000000;
                margin-top: 1rem;
            }
        }

        .image_container {
            height: 100vh;
            max-height: 800px;
            background-size: 100%;
            transition: 1s background-size;
            background-color: $primary;
            &:hover {
                background-size: 115%;
                transition: 1s background-size;
            }
            &[class*=ce_].has-h1-style h2:first-of-type, h2{
                color: $white !important;
            }
            &h2, .h2{
                color: $white !important;
                &.headline-indented.text-secondary{
                    color: $white !important; 
                }
            }
            .ce_text {
                display: flex;
                align-items: center;
                height: 100%;
                margin-bottom: 0;

                @include media-breakpoint-down(sm) {
                //background-color: $primary;
                align-items: flex-start;
                //padding-top: 1.5rem;
                }
            }
        }
    }
}