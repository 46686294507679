@use "sass:math";

#bbiz-wettbewerb {
  .background-l0 {
    position: absolute;

    @include media-breakpoint-up(sm) {
      @include make-col-ready();
      @include make-col(3);
      position: absolute;
    }
    @include media-breakpoint-up(xl) {
      @include make-col(4);
      margin-left: -$grid-gutter-width * 2.5
    }

		padding: 0;
		margin-top: math.div($grid-gutter-width, 2);

    #wettbewerb-background {
      width: 86%;
      margin-top: 14px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        transform: translate(0, 200px);
        padding: 0;
        margin: 0;

        .pfeil {
          display: none;
        }
        .pokale-small {
          display: none;
        }
      }
    }
  }

  > .content {
    @include make-row();

    > .figure {
      @include make-col-ready();
      @include make-col(12);
      text-align: center;

      #wettbewerb-pokal {
        width: 30%;
      }

      @include media-breakpoint-up(md) {
        @include make-col(2);
        @include make-col-offset(2);
        text-align: right;

        #wettbewerb-pokal {
          margin-top: 40px;
          width: 65%;
          padding-right: 10px;
        }
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
    }
  }
}
