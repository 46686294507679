#header,
.mm-menu {
  background: $gradient;

  // NAV ITEMS
  .header-nav-tools,
  .sticky-nav {
    a {
      color: $white;
      text-align: center;

      &:hover {
        color: $yellow !important;
      }
    }

    .nav-link.active,
    .nav-link.submenu.trail {
      color: $yellow !important;

      &:hover {
        color: $yellow !important;
      }

      a {
        &:hover {
          color: $yellow !important;
        }
      }
    }

    // LOGO


    img {
      padding-bottom: 1rem;

      @include media-breakpoint-down(sm) {
        width: 70px;
        padding-bottom: 0;
      }

      @include media-breakpoint-down(lg) {
        height: 80px;
      }
    }

  }

  .navbar-secondary {
    // Top Navigation

    .mod_customnav {
      a {
        &:after {
          color: $yellow;
        }
        span {
          padding: 0 0.5rem 0 0.5rem;
        }
      }
    }
  }

  .sticky-nav {
    background: $gradient;

    img {
      height: 55px;
      padding-bottom: 0;
    }

    .mod_customnav {
      a {
        color: $white;
      }
    }
  }

  // SUBMENU
  .nav-item.submenu {
    &>a {
      @include beg-icon($beg-icon-arrow_carrot-down, "after");

      &:after {
        vertical-align: middle;
        font-size: 1.5rem;
        color: $yellow;
      }
    }
  }

  .header-top,
  .sticky-nav {
    .navbar {
      .level_1.navbar-nav {
        z-index: 100;
      }

      .level_2,
      .level_3 {
        z-index: 200;

        &:before {
          background: $gradient;
        }

        .container {
          a {
            color: $white !important;

            &:hover {
              color: $yellow !important;
            }
          }
        }
      }
    }
  }

  .header-top.bahnland-header,
  .sticky-nav.bahnland-header {
    .mobile-tools-wrapper .extra {
      padding: 0 1rem;
      align-items: center;

      .mod_iso_cart_link {
        a {
          font-size: 0;

          &:after {
            font-size: 2.5rem;
          }

          .badge {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .sticky-nav.bahnland-header {
    .link-home-mobile {
      display: none;
    }
  }
}

#header,
.navbar-mobile-bottom {

  // SEARCH FIELD
  .mod_search {
    margin: 0 2rem 0 0;

    @include media-breakpoint-down(md) {
      margin: 0 0.5rem 0 0.5rem;
    }

    .input-group {
      input[type="search"] {
        border-bottom-color: $yellow !important;
        color: $white;

        &::placeholder {
          color: $white;
        }

        &:hover,
        &:focus {
          border-top-color: $yellow;
        }
      }

      button[type="submit"] {
        color: $yellow;

        &:hover {
          color: $white !important;
        }
      }
    }

    .visually-hidden {
      color: #fff !important;
    }
  }

}

#header .navbar-tools,
.navbar-mobile-bottom,
.mm-menu {
  .accessibility-bar {

    .motion-toggle-wrapper,
    .contrast-toggle-wrapper {
      button {
        color: $white !important;
        background-color: transparent;

        &:hover {
          color: $yellow;
        }

        &[aria-checked="true"] {
          color: $white;
          &:before {
            color: $yellow;
          }
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }

  .easy-lang-link {
    a {
      color: $white !important;
      background-color: transparent;

      &:hover {
        color: $yellow !important;
      }
    }
  }
}

.header-top.bahnland-header,
.sticky-nav.bahnland-header {
  .mod_iso_cart_link {
    a {
      @include beg-icon($beg-icon-cart, after);
      display: flex;
      position: relative;
      margin-left: 1rem;
      text-decoration: none;
      font-weight: $font-weight-bold;

      &:after {
        color: $yellow;
        font-size: 1.5rem;
        display: inline-block;
        margin-left: 0.3rem;
      }

      .badge {
        background: white;
        color: $primary;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        position: absolute;
        right: -12px;
        top: -8px;
      }

      &:hover {
        .badge {
          background: $yellow;
        }
      }
    }
  }
}

.container-md,
.container-sm,
.container,
#hero>.inside,
.ce_huh_hero .hero-container {
  @include media-breakpoint-up(md) {
    max-width: 960px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1140px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1320px;
  }
}

// Logo mobile im Menü

#bahnland_navigation_top_mobile {

  @include media-breakpoint-down(sm) {

    .link-home-mobile {
      img {
        width: 70px;
        padding-bottom: 0;
      }
    }
  }
}