.price-box {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    header {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        img {
            align-self: center;
        }
        img:nth-child(2) { 
            margin-left: 25px;
            height: 50px;
          }

        .price {
            color: $yellow;
            font-family: $headings-font-family;
            font-size: $h1-font-size;
            font-style: $headings-font-style;
            font-weight: $headings-font-weight;
            margin-left: auto;
        }
    }
    article {
        color: #fff;
        display: flex;
        flex-direction: row;

        > div {
            padding-right: 0.5rem;
        }

        a {
            align-self: center;
            display: block;
            width: 50px;
            text-decoration: none !important;
            margin-left: 0.2rem;
            i {
                border-radius: 50%;
                border: 2px solid #fff;
                color: $yellow;
                display: flex;
                font-size: 3rem;
                height: 50px;
                width: 50px;
                &::before {
                    transition: 200ms transform;
                }
            }
            &:hover {
                text-decoration: none;
                i::before {
                    transform: scale(1.2);
                    transition: 200ms transform;
                }
            }
        }
        p {
            margin-bottom: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
    }
}

.ticket-more-info {
    color: #fff;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    a {
        align-self: center;
        display: block;
        width: 50px;
        text-decoration: none !important;
        margin-left: 1rem;
        em {
            border-radius: 50%;
            border: 2px solid #fff;
            color: $yellow;
            display: flex;
            font-size: 3rem;
            height: 50px;
            width: 50px;
            &::before {
                transition: 200ms transform;
            }
        }
        &:hover {
            text-decoration: none;
            i::before {
                transform: scale(1.2);
                transition: 200ms transform;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
}

.download-info {
    a {
        display: flex;
        flex-direction: row;
        margin-top: 3rem;
        text-decoration: none !important;
        color: $white;

        &:hover {
            color: $white;

            em {
                text-decoration: none;
                &::before {
                    transform: scale(1.2);
                    transition: 200ms transform;
                }
            }
        }

        .txt {
            align-self: center;
            display: block;
            strong {
                font-family: $font-family-poppins;
                font-style: italic;
            }
        }
        .icon {
            margin-left: auto;
            .beg-icon-download {
                border: 0 none;
            }
            em {
                border-radius: 50%;
                border: 2px solid #fff;
                color: $yellow;
                display: flex;
                font-size: 2.4rem;
                height: 50px;
                width: 50px;
                align-items: center;
                &::before {
                    transition: 200ms transform;
                }
            }
        }
    }
}

.text-white {
    h1, h2, h3, h4, h5, h6 {
        color: $white;
    }
}
