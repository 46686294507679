#bbiz-freistaat {
  .background-l0 {

    #bbiz-freistaat-background {
      @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: 32%;
        width: 100%;
      }
    }
  }

  > .content {
    @include make-row();

    > .figure {
      @include make-col-ready();
      @include make-col(12);
      text-align: center;

      #bbiz-freistaat-left {
        width: 20%;
      }

      @include media-breakpoint-up(md) {

        text-align: unset;
        @include make-col(2);
        @include make-col-offset(2);

        #bbiz-freistaat-left {
          width: unset;
        }

      }
      @include media-breakpoint-up(lg) {
        @include make-col-offset(4);
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
        padding-top: $grid-gutter-width;
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
  }
}
