#bbiz-hoehenmeter {

  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width *3;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width * 2;
  }

  .background-l0 {

    .rail {
      opacity: 0.2;
    }

    @include media-breakpoint-up(lg) {
      width: 45%;
    }

    @include media-breakpoint-only(md) {
      width: 100%;
      margin-top: 14%;
    }

    @include media-breakpoint-down(sm) {
      top: 30%;

      .pfeil {
        display: none;
      }
    }
  }

  > .content-wrapper {
    @include make-container();
    max-width: map-get($container-max-widths, xxl);

    > .content {
      @include make-row();

      > .figure {
        @include make-col-ready();
        @include make-col(12);
        text-align: center;

        #bbiz-hoehenmeter-left {
          width: 30%;
        }

        @include media-breakpoint-up(md) {
          @include make-col(3);
          @include make-col-offset(2);
          text-align: right;
          #bbiz-hoehenmeter-left {
            width: 60%;
          }
        }
        @include media-breakpoint-up(lg) {
          @include make-col(2);
          @include make-col-offset(4);
        }
      }

      > .text {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
      }
    }
  }
}
