#quicklinks {
    margin-bottom: 4.5rem;
    .col {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
        padding-top: 2rem;
        transform: scale(1);
        transition: 200ms transform ease;
        &:hover {
            transform: scale(1.1);
            transition: 200ms transform ease;
            z-index: 300;
        }
        a {
            color: #fff;
            display: block;
            font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
            font-size: 1.7rem;
            font-style: oblique;
            font-weight: bold;
            padding: 0 1rem;
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;
            img {
                display: block;
                height: 70px;
                margin: 0 auto 2rem;
            }
        }
    }
}

.ce_image.group {
    .image_container {
        img {
            width: 80%;
        }
    }
}

// body.tickets {
//     .btn.btn-primary {
//         margin-top: 2rem;
//     }
// }

// .frequent-driver {
//     transition: 1s background-size;
//     &:hover {
//         background-size: 115%;
//         transition: 1s background-size;
//     }
//     p.lead { 
//         text-shadow: 11px 11px 40px #000000;
//     }
//     &h2 { 
//         text-shadow: 5px 5px 30px #000000;
//     }
// }

.hero-block-right-wrapper {
    margin-left: -1rem;
    margin-right: -1rem;
    background-color: $primary;
}
.frequent-driver {
    .hero-block-right {
        p.lead{ 
            //text-shadow: 1px 1px 20px #000000;
            width: 70%;
            color: $white !important;
            text-shadow: 1px 1px 12px #000000;

            @include media-breakpoint-down(sm) {
                color: $white !important;
                width: 100%;
            }
        }
        h2{
            text-shadow: 5px 5px 30px #000000;
            @include media-breakpoint-down(sm) {
                text-shadow: 0px 0px 0px #000000;
                margin-top: 1rem;
            }
        }

        .image_container {
            height: 100vh;
            max-height: 800px;
            background-size: 100%;
            transition: 1s background-size;
            background-color: $primary;
            &:hover {
                background-size: 115%;
                transition: 1s background-size;
            }
            &[class*=ce_].has-h1-style h2:first-of-type, h2{
                color: $white !important;
            }
            &h2, .h2{
                color: $white !important;
                &.headline-indented.text-secondary{
                    color: $white !important; 
                }
            }
            .ce_text {
                display: flex;
                align-items: center;
                height: 100%;
                margin-bottom: 0;

                @include media-breakpoint-down(sm) {
                //background-color: $primary;
                align-items: flex-start;
                //padding-top: 1.5rem;
                }
            }
        }
    }
}

// Karte Ticket Verbünde

.st1{
    fill: $white !important;
    &:hover{
        fill: $blue-light !important;
        transition: all 0.1s ease-out;
    }
}
// .st2{
//     &:hover{
//         fill: $white;
//         transition: all 0.1s ease-out;
//         transition: transform 0.3s ease-in-out;
//     }
// }

.network {
    li {
     margin-bottom: 0;
    }

    a {
        text-decoration: none !important;
        font-weight: $font-weight-medium;
        background: none !important;
        //&::before {
        //    font-family: "beg-icons";
        //    background-image: none !important;
        //    width: 2rem;
        //    height: 2rem;
        //    speak: none;
        //    font-size: 1.2rem;
        //    margin-left: 0;
        //    font-style: normal;
        //    color: $primary;
        //    font-weight: normal;
        //    font-variant: normal;
        //    text-transform: none;
        //    line-height: 0.4rem;
        //    content: "\e753";
        //}
        &:hover {
            text-decoration: underline !important;
        }
    }
}

.validityBox {
    .image_container {
        img {
            object-fit: contain;
            background-color: $white;
        }
    }
}

.priceTables {
    span.count {
        display: none;
    }
}
.ce_huh_hero {
    .hero-container {
        .image_container {
            overflow: hidden;
        }
    }
}
