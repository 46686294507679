#bahnland-bayern-magazin, #bahnland-bayern-intern {
  margin-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 4rem;
  }

  .col-12 {
    padding: 0;
  }

  .ce_text.block {
    height: 450px;
    padding: 2rem;
    color: $white;
    h2 {
      color: $yellow;
    }
  }

  input[type=number] {
    color: $white;
  }

  .tiny-slider, .tns-outer {
    height: 100%;

    button[data-controls=next], button[data-controls=prev] {
      top: 49%;
    }

    .tns-nav {
      @include media-breakpoint-down(md) {
        bottom: -1rem;
      }
      @include media-breakpoint-down(sm) {
        bottom: -3rem;
      }
    }
  }

  .magazine-slider-item {
    position: relative;
    overflow: hidden;
    height: 450px;

    .content {
      position: absolute;
      bottom: -78px;
      background: rgba(0,0,0,0.7);
      height: 30%;
      width: 100%;
      padding: 1rem;
      color: $white;
      transition: all 0.6s ease;

      h4 {
        margin-bottom: 1rem;
        color: $white;
        text-decoration: underline;
      }
    }

    &:hover, &:focus, &.hovered {
      .image {
        transform: scale(1) translateY(0) translateZ(0);
      }
      .content {
        bottom: 0;

        input {
          border: 1px solid $white !important;
        }
      }
    }
  }

  .ce_text {
    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
    }
  }

}

#bahnland-bayern-magazin {
  button[data-controls=prev]:not([disabled]) {
    color: $white;
      @include media-breakpoint-down(md) {
        color: $primary;
      }
  }

  .btn-primary {
    &:hover {
      background-color: $white !important;
      color: $primary !important;
    }
  }

  .image {
    position: absolute;
    height: 100%;
    width: 100%;
    //transform: scale(4.5);
    transform: scale(3) translateY(85px);
    backface-visibility: hidden;
    transition: all 0.6s ease;
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 70%;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      right: 0;
      -webkit-backface-visibility: hidden;
      transform: translateZ(0);
    }
  }
}

#bahnland-bayern-intern {
  .tns-inner {
    .image {
      height: 100%;

      a {
        height: max-content;
      }
    }
  }

  .tns-nav {
    bottom: -2rem !important;
  }
}

.cart-qty {
  input[type=number] {
    color: $white;
    &:active,
    &:focus,
    &:target {
      color: $black !important;
    }
  }
}
