form {
  textarea,
  input[type="text"]:not([data-enpassusermodified]):not([aria-invalid]) {
    color: $black !important;
    &:focus,
    &::placeholder {
      color: $black !important;
    }
  }

  .choices[role="combobox"] {
    flex: 1;
    .choices__inner {
      border-top-right-radius: $input-border-radius !important;
      border-bottom-right-radius: $input-border-radius !important;
    }
    .choices__item {
      padding-left: 1rem;
    }
  }

  button[type="submit"].btn.btn-reset-muted {
    background-color: unset;
    text-transform: unset;
    transform: none;
    padding: 0;
    font-weight: $font-weight-light;
    color: $text-muted;
    transition: $transition-base;
    font-family: $font-family-roboto;

    @include beg-icon($beg-icon-close, "after");

    &:after {
      padding-left: 0.5rem;
      vertical-align: initial;
    }

    &:hover {
      background-color: transparent !important;
      color: $primary !important;
    }
  }

  .formbody {
    .form-group,
    .additionalFiles,
    .file {
      label {
        color: $primary;
        font-weight: $font-weight-bold;
        margin-bottom: $form-label-margin-bottom;
      }

      select {
        margin: 0;
      }

      &.widget-checkbox {
        label {
          padding: 0;
        }
      }

      & + legend {
        margin-top: 2rem;
      }
    }

    .widget-submit {
      button[type="submit"] {
        font-weight: 800;
      }
    }

    .form-column-login {
      .form-group {
        width: auto;
      }
    }
  }

  .dropzone {
    margin-bottom: 1.5rem;
    border-color: $input-border-color !important;

    &,
    .dz-container,
    .dz-message {
      border-radius: $input-border-radius;
    }
  }

  .autoComplete_result {

    border-color: transparent;
    border-width: 2px;

    &:last-child {
      border-radius: 0 0 $input-border-radius $input-border-radius;
    }

    &.autoComplete_selected,
    &:hover,
    &:focus,
    &[aria-selected=true]{
      border-color: $primary;
      //border-right-color: $primary;
      background-color: $light;
    }
  }

  &.form-calendar {
    @extend .mb-5-ni;
    @extend .mb-md-6-ni;

    .formbody {
      @include make-row();

      .form-group {
        @include make-col-ready();

        &:not(.uploadPhoto):not(.explanation):not(.agreement):not(.agreementPhoto):not(.agreementNewsletter):not(.routeStart):not(.routeEnd):not(.age) {
          @include media-breakpoint-up(md) {
            @include make-col(6);
          }
        }

        &.explanation {
          &:not(.routeInformation) {
            margin-bottom: 1.5rem;
            @include font-size($small-font-size);
          }

          &.routeInformation {
            font-weight: $font-weight-bold;
            color: $primary;
          }
        }

        &.legalAge {
          legend {
            @include font-size($font-size-base);
            margin-bottom: 1.5rem;
          }

          .form-check {
            display: inline-block;
            margin-right: 1rem;
          }
        }

        &.phone {
          @include media-breakpoint-up(md) {
            @include make-col-right-offset(6);
          }
        }

        &.routeStart,
        &.routeEnd {
          @include make-col(6);

          @include media-breakpoint-up(md) {
            @include make-col(3);
          }
        }
      }
    }
  }
}

.goin-form {
  form {
    .formbody {
      display: flex;
      flex-wrap: wrap;

      .widget {
        flex-basis: 100%;

        &.gender {
          .radio-group {
            display: flex;
            .form-check {
              cursor: pointer;
              flex-basis: 20%;
            }
          }
        }

        &.salutation {
          flex-basis: 20%;
          padding-right: 1rem;
        }
        &.firstname {
          flex-basis: 40%;
          padding-right: 1rem;
        }
        &.lastname {
          flex-basis: 40%;
        }
        &.street_1 {
          flex-basis: 60%;
          padding-right: 1rem;
        }
        &.street_2 {
          flex-basis: 40%;
        }
        &.postal {
          flex-basis: 30%;
          padding-right: 1rem;
        }
        &.city {
          flex-basis: 70%;
        }
        &.phone {
          flex-basis: 50%;
          padding-right: 1rem;
        }
        &.email {
          flex-basis: 50%;
        }
        .consent {
          p {
            display: inline;
          }
        }
      }
    }
  }
}
