.next-app-teaser {
  .box-content.text-left {
    padding: 1rem 1.9rem;
  }
}

body.high-contrast {
  .box-content.text-left {
    .text-secondary {
      color: white !important;
    }
  }
}
