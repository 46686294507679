@import "../../common/scss/variables";

$font-path: "..";
$svg-path: "../../../common/fonts/beg-svg-icons";
$svg-icon-path: "../../fonts/beg-svg-icons";

// Typography

$h1-font-size: 3.25rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 1.125rem;

// Buttons

$btn-transform: skew(-10deg);
$btn-primary-bg-color: $yellow;
$btn-primary-color: $primary;
$btn-font-weight: $font-weight-extra-bold;
$btn-font-family: $font-family-poppins;
$btn-line-height: 1.5;
$btn-padding-y: 0.3rem;
$btn-padding-x: 2rem;

// Forms

$form-label-font-weight: $font-weight-bold;
$form-label-color: $primary;

$input-border-color: $cyan;
$input-border-width: 1px;
$input-border-radius: 0.3rem;

$input-color: $primary;

$input-placeholder-color: $primary;

$legend-margin-bottom: 2rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px
);
