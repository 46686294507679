#bbiz-13-mio-fahrgaeste-pro-tag {
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 2.5;
  }

  .background-l0 {
    text-align: right;
    padding-right: 9%;

    @include media-breakpoint-up(md) {
      margin-top: -10%;
    }

    #clock {
      width: 32%;

      .pfeil {
        transform: translate(0, 30px);
      }

      @include media-breakpoint-down(sm) {
        width: 65%;

        .pfeil {
          display: none;
        }
      }
    }
  }

  .background-l1 {

    text-align: right;

    @include media-breakpoint-up(lg) {
      margin-top: -4%;
    }

    @include media-breakpoint-only(md) {
      display: none;
    }

    #people-right {
      width: 32%;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }
  }

  > .content {
    @include make-row();

    > .figure {
      @include make-col-ready();
      @include make-col(6);
      @include media-breakpoint-only(md) {
        @include make-col(3);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      #people-left {
        margin-left: -4%;
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-only(md) {
        @include make-col(9);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
        .inner {
          padding-left: $grid-gutter-width;
        }
      }
    }
  }
}
