.ce_survey {
    background-color: #ffffff;
    padding: 3rem;

    @include media-breakpoint-down(sm) {
        padding: 1.5rem;
      }

    .form {
        .pagetitle {
            
        }

        .formbody {
            .surveyblock {

                &.logo {
                    img {
                        display: inline-block;
                        margin-left: 1rem;
                        @include media-breakpoint-down(sm) {
                            border-bottom: 1px solid $primary;
                            padding-bottom: 1rem;
                            padding-top: 1rem;
                            margin-bottom: 1rem;
                          }
                        
                    }
                    label {
                        @include media-breakpoint-down(sm) {
                            display: inline;
                          }
                    }
                }
                .question {
                    font-size: 1.5rem;
                    color: $primary;
                    font-style: italic;
                    font-weight: 700;
                    p {
                        margin-bottom: 0.5rem;
                        margin-top: 0.5rem;
                        @include media-breakpoint-down(sm) {
                            line-height: 1.7rem;
                          }
                    }
                }
                .widget {
                    @include media-breakpoint-down(sm) {
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column-reverse;
                      }
                }
            }
        }
        .submit_container {
            display: flex;
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
              }
              .left, .right {
                @include media-breakpoint-down(sm) {
                    display: flex;
                    justify-content: center;
                  }
            }
            .middle {
                margin-left: 1rem;
                @include media-breakpoint-down(sm) {
                    margin-top: 1rem;
                  }
            }
        }
    }
}


.survey_usercategories {
    h2 {
        @include media-breakpoint-up(md) {
            font-size: 3.3rem;
          }
    }
}



// Fortschrittsbalken

.progressbar_container {
	//background: url(files/survey/images/percentage-bg.png) no-repeat left center;
	width: 316px;
	height: 39px;
	margin: 0 auto;
    background-color: $blue-light;
    @include media-breakpoint-down(sm) {
        width: 250px;
        margin-bottom: 2rem;
      }
}
.progressbar {
	//background: url(files/survey/images/progress.png) no-repeat left center;
	width:  0%;
	height:  39px;
    background-color: $secondary;
}
.progressbar span {
	visibility: hidden;
	display: none;
}

