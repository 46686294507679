@use "sass:math";

#bbiz-app {

  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width *2;
  }

  .background-l0 {
    #bbiz-app-background {

      @include media-breakpoint-up(lg) {
        width: 90%;
      }

      @include media-breakpoint-down(sm) {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }

  > .content {
    @include make-row();

    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width * 2;
    }

    @include media-breakpoint-only(md) {
      padding-top: $grid-gutter-width * 1.5;
    }

    > .figure {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(3);
        text-align: right;
      }
      @include media-breakpoint-only(md) {
        margin-top: 5px;
      }
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: math.div($grid-gutter-width, 2);
      }

      #bbiz-app-left {
        width: 45%;
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }

      .h1 {
        margin-top: 0;
      }
    }

    .dropdown-toggle, .dropdown-menu, .dropdown-menu .dropdown-item {
      background-color: $white !important;
      color: $primary !important;
    }

    .visible-android {
      .android & {
        display: inline-block !important;
      }
    }

    .visible-ios {
      .ios & {
        display: inline-block !important;
      }
    }
  }
}
