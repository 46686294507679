@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?kisam');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?kisam#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?kisam') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kisam') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kisam') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kisam##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.beg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.beg-icon-external-link-thin {
  &:before {
    content: $beg-icon-external-link-thin; 
  }
}
.beg-icon-arrow-round-right {
  &:before {
    content: $beg-icon-arrow-round-right; 
  }
}
.beg-icon-avatar {
  &:before {
    content: $beg-icon-avatar; 
  }
}
.beg-icon-paperclip {
  &:before {
    content: $beg-icon-paperclip; 
  }
}
.beg-icon-checklist {
  &:before {
    content: $beg-icon-checklist; 
  }
}
.beg-icon-network {
  &:before {
    content: $beg-icon-network; 
  }
}
.beg-icon-calendar-xmark {
  &:before {
    content: $beg-icon-calendar-xmark; 
  }
}
.beg-icon-cycle {
  &:before {
    content: $beg-icon-cycle; 
  }
}
.beg-icon-document {
  &:before {
    content: $beg-icon-document; 
  }
}
.beg-icon-info {
  &:before {
    content: $beg-icon-info; 
  }
}
.beg-icon-pen-to-square {
  &:before {
    content: $beg-icon-pen-to-square; 
  }
}
.beg-icon-image {
  &:before {
    content: $beg-icon-image; 
  }
}
.beg-icon-download-arrow {
  &:before {
    content: $beg-icon-download-arrow; 
  }
}
.beg-icon-bus {
  &:before {
    content: $beg-icon-bus; 
  }
}
.beg-icon-calendar-check {
  &:before {
    content: $beg-icon-calendar-check; 
  }
}
.beg-icon-camera-solid {
  &:before {
    content: $beg-icon-camera-solid; 
  }
}
.beg-icon-cloud-sun {
  &:before {
    content: $beg-icon-cloud-sun; 
  }
}
.beg-icon-map {
  &:before {
    content: $beg-icon-map; 
  }
}
.beg-icon-xing {
  &:before {
    content: $beg-icon-xing; 
  }
}
.beg-icon-linkedin {
  &:before {
    content: $beg-icon-linkedin; 
  }
}
.beg-icon-whatsapp {
  &:before {
    content: $beg-icon-whatsapp; 
  }
}
.beg-icon-envelope {
  &:before {
    content: $beg-icon-envelope; 
  }
}
.beg-icon-facebook {
  &:before {
    content: $beg-icon-facebook; 
  }
}
.beg-icon-twitter {
  &:before {
    content: $beg-icon-twitter; 
  }
}
.beg-icon-activity {
  &:before {
    content: $beg-icon-activity; 
  }
}
.beg-icon-cart {
  &:before {
    content: $beg-icon-cart; 
  }
}
.beg-icon-download {
  &:before {
    content: $beg-icon-download; 
  }
}
.beg-icon-house {
  &:before {
    content: $beg-icon-house; 
  }
}
.beg-icon-minus {
  &:before {
    content: $beg-icon-minus; 
  }
}
.beg-icon-plus {
  &:before {
    content: $beg-icon-plus; 
  }
}
.beg-icon-clock {
  &:before {
    content: $beg-icon-clock; 
  }
}
.beg-icon-calendar {
  &:before {
    content: $beg-icon-calendar; 
  }
}
.beg-icon-marker {
  &:before {
    content: $beg-icon-marker; 
  }
}
.beg-icon-easy-lang-active {
  &:before {
    content: $beg-icon-easy-lang-active; 
  }
}
.beg-icon-chat {
  &:before {
    content: $beg-icon-chat; 
  }
}
.beg-icon-chat-bavarian {
  &:before {
    content: $beg-icon-chat-bavarian; 
  }
}
.beg-icon-easy-lang {
  &:before {
    content: $beg-icon-easy-lang; 
  }
}
.beg-icon-contrast-full {
  &:before {
    content: $beg-icon-contrast-full; 
  }
}
.beg-icon-contrast {
  &:before {
    content: $beg-icon-contrast; 
  }
}
.beg-icon-animation-play {
  &:before {
    content: $beg-icon-animation-play; 
  }
}
.beg-icon-animation-stop {
  &:before {
    content: $beg-icon-animation-stop; 
  }
}
.beg-icon-search {
  &:before {
    content: $beg-icon-search; 
  }
}
.beg-icon-arrow_carrot-up-double {
  &:before {
    content: $beg-icon-arrow_carrot-up-double; 
  }
}
.beg-icon-arrow_carrot-up_alt2 {
  &:before {
    content: $beg-icon-arrow_carrot-up_alt2; 
  }
}
.beg-icon-arrow_carrot-up {
  &:before {
    content: $beg-icon-arrow_carrot-up; 
  }
}
.beg-icon-arrow_carrot-right_alt2 {
  &:before {
    content: $beg-icon-arrow_carrot-right_alt2; 
  }
}
.beg-icon-arrow_carrot-right {
  &:before {
    content: $beg-icon-arrow_carrot-right; 
  }
}
.beg-icon-arrow_carrot-left_alt2 {
  &:before {
    content: $beg-icon-arrow_carrot-left_alt2; 
  }
}
.beg-icon-arrow_carrot-left {
  &:before {
    content: $beg-icon-arrow_carrot-left; 
  }
}
.beg-icon-arrow_carrot-down_alt2 {
  &:before {
    content: $beg-icon-arrow_carrot-down_alt2; 
  }
}
.beg-icon-arrow_carrot-down {
  &:before {
    content: $beg-icon-arrow_carrot-down; 
  }
}
.beg-icon-close {
  &:before {
    content: $beg-icon-close; 
  }
}

