#footer {
  .footer-app {
    margin-top: auto;
    margin-top: 8rem !important;
    margin-bottom: 8rem;

    @include media-breakpoint-down(sm) {
      margin-top: 6rem !important;
      margin-bottom: 2rem;
    }

    .footer-app-blue {
      color: $secondary;
    }

    .icon-footer {
      margin-bottom: 0.4rem;
      margin-top: 6.5rem;

      @include media-breakpoint-down(sm) {
        margin-top: 1.5rem;
      }

      .image_container {
        margin-bottom: 0.8rem;
        justify-content: left;
        height: 40px;
        
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .mobile-app {
      justify-content: left;
      display: block;

      .image_container {
        justify-content: left;
      }
    }

    .ce_linkteaser {
      a {
        text-decoration: none;
      }
    }

    @include media-breakpoint-down(sm) {
      text-align: center;

      .image_container {
        justify-content: center;

        img {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .app {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;
        margin-top: 1rem !important;
      }

      h2 {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      h3 {
        margin-top: 1rem !important;
        margin-bottom: 0.4rem !important;
      }

      .ce_hyperlink {
        width: 80%;
      }

      .icon-footer {
        margin-bottom: 0.4rem;

        .image_container {
          margin-bottom: 0.8rem;
          height: 40px;
          img {
            height: 100%;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }

  .footer-hero {
    .ce_huh_hero {
      overflow: hidden;
      position: relative;

      @include media-breakpoint-down(md) {
        background: $gradient;
        padding-bottom: 2rem;
      }

      &::before {
        width: 70vw;
        @include media-breakpoint-up(md) {
          content: "";
          background: url(../../../common/img/block/block-footer.svg) left
            top no-repeat transparent;
          background-size: cover;
          display: block;
          position: absolute;
          height: 100%;
          z-index: 1;
          top: 0;
          right: 0;
        }
        @include media-breakpoint-up(lg) {
          width: 55vw;
        }
      }

      .hero-container {
        @include media-breakpoint-up(md) {
          height: 80vh;
          min-height: 580px;
          max-height: 670px;
        }

        & + div {
          display: flex;
          flex-direction: column;
        }
        .img {
          margin: 2rem auto 1rem;
          @include media-breakpoint-up(md) {
            margin: 2rem 1rem 2rem auto;
          }
        }

        .text-container {
          @include media-breakpoint-down(md) {
            position: unset !important;
          }

          .h1 {
            justify-content: center;
            display: flex;
            flex-direction: column;
            margin: 0 auto 2rem auto;

            @include media-breakpoint-up(sm) {
              @include font-size(3rem);
            }
            @include media-breakpoint-down(md) {
              @include font-size(3.75rem);
            }
            @include media-breakpoint-down(lg) {
              @include font-size(3.25rem);
            }
            @include media-breakpoint-up(xl) {
              @include font-size(3.7rem);
            }
          }
        }
      }
    }
  }

  a.invisible {
    color: #fff;
  }
}
