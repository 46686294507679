.btn-primary {
  margin-left: 3px;

  &:hover {
    background-color: $primary !important;
    color: $white !important;

    a {
      color: $white !important;
    }
  }
}

.tiny-slider {
  .tns-nav {
    button {
      @include media-breakpoint-down(sm) {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}
