.app-icon-container {
    .item {
        padding-inline: 2rem;

        img {
            margin-inline: auto;
        }
    }
}

// Listenansichten

// Filter

// .mod_filter {
//     .discountticket {
//         display: flex;
//         flex-direction: row;
//     }
// }

// Liste / Tabelle

.mod_huhlist {
    &.tl_discount_ticket {
        ∏ .table.discountticket {
            .thead {
                background-color: $primary;
                color: $white;
            }
        }
    }
}

// Print

@media print {

    body.discount-ticket {
        -webkit-print-color-adjust: exact;
        padding: 0;
        margin: 0;
        font-size: 10pt;
        line-height: 1.2;

        @page {
            margin-top: 1.5cm;
            margin-right: 0.5cm;
            margin-bottom: 1cm;
            margin-left: 0.5cm;
        }
    
        /* Standardmäßig alles verstecken */
        .print-list {
            display: none !important;
        }
    
        /* Liste speziell für den Druck anzeigen */
        .print-visible {
            display: block !important;
        }
    
        .mod_article {
            width: 100%;
        }
    
        
    
        >#wrapper #main {
            padding-top: 0;
        }
    
        p {
            //page-break-inside: avoid;
            font-size: 9pt;
            line-height: 1.4;
            text-align: justify;
            hyphens: auto;
        }
    
        .lead {
            width: 100%;
        }
    
        .lead p {
            font-size: 12pt;
        }
    
        h2 {
            margin-top: 0.2in;
            margin-bottom: 0.2in;
            font-size: 15pt;
        }
    
        h3 {
            margin-bottom: 0.2in;
            font-size: 12pt;
            color: #000000;
        }
    
        .container {
            max-width: 100%;
        }
    
        img {
            width: 50%;
        }
    
        .ce_image {
            margin-top: 0 !important;
            margin-bottom: 0.2in !important;
        }

        .image_container {
           justify-content: end !important;
        }
    
        .ce_text {
            margin-top: 0 !important;
            margin-bottom: 0.3in !important;
            width: 100%;
        }
    
        table {
            // page-break-inside: auto;
            // page-break-after: auto;
            width: 100% !important;
            margin-bottom: 0;
            font-size: 9pt;
        }

        .col-md-8 {
            width: 100%;
        }
    
        #hero,
        .hero,
        #header,
        #footer,
        .sticky-nav,
        .offcanvas,
        .offcanvas-backdrop.show,
        #usercentrics-root,
        .pagination-wrapper {
            display: none !important;
        }
    }

   
}