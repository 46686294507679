#bahnland-bayern-news, #halte-strecken, #bahnland-bayern-karten-plaene, #latest-publications, #bahnland-bayern-ausflugstipps, #stationsflyer {
  .container {
    padding: 3rem 0;

    @include media-breakpoint-down(xs) {
      padding: 0 0.75rem;
    }
  }

  .ce_colsetStart {
    .col-12.col-md-6 {
      padding: 2rem;
    }
  }

  .tiny-slider-container {
    .tns-item {
      .news-slider-item {
        padding: 2rem;
        height: 500px;

        .content {
          height: 100%;

          .image {
            flex: 1;
            padding: 1rem;
            overflow: hidden;

            .image_container {
              a {
                height: 100%;
              }

              img {
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  .tns-nav {
    bottom: -3rem;
    right: 0rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .ce_colsetStart.col-full {
    padding-top: 3rem;
    //padding-bottom: 2rem;
  }

  .ce_colsetStart.bg-white {
    .tns-item {
      button{
        border-color: $primary;

        &:before {
          color: $primary;
        }

        &:hover {
          &:before {
            color: $white;

          }
        }
      }
    }

    input[type=number] {
      border-color: $primary;
      color: $primary;
    }
  }

  .ce_colsetStart.bg-primary {
    color: $white;

    h3, h4 {
      color: $yellow;
    }
  }

  .ce_colsetStart.bg-blue {
    .tiny-slider .tns-nav button:not(.tns-nav-active) {
      background-color: white;
    }
  }
}

#bahnland-bayern-news, #latest-publications {
  .tns-item {
    h4 {
      margin-bottom: 0;
    }

    &:nth-child(3n+1) {
      background: $blue;

      input[type=number] {
        border-color: $primary;
        color: $primary;
      }

      button {
        border-color: $primary;

        &:before {
          color: $primary;
        }

        &:hover {
          &:before {
            color: $white;
          }
        }
      }
    }

    &:nth-child(3n+2) {
      background: $secondary;

      button {
        border-color: $white;

        &:before {
          color: $white;
        }

        &:hover {
          &:before {
            color: $white;
          }
        }
      }

      input {
        border: 1px solid $white !important;
      }
    }

    &:nth-child(3n+3) {
      background: $primary;

      button {
        border-color: $white;

        &:before {
          color: $white;
        }

        &:hover {
          &:before {
            color: $white;
          }
        }
      }

      input {
        border: 1px solid $white !important;
      }

      button {
        border-color: $white;

        &:before {
          color: $white;
        }

        &:hover {
          &:before {
            color: $white;
          }
        }
      }
    }

    &:nth-child(3n+2), &:nth-child(3n+3) {
      color: $white;

      h4 {
        color: $white;
      }

      // .downloadlink .submit {
      //   color: $white;
      // }
    }
  }
}

#halte-strecken, #bahnland-bayern-highlights, #bahnland-bayern-ausflugstipps, #stationsflyer {
  .news-slider-item, .shop-highlight-item {
    input[type=number] {
      border-color: $primary;
      color: $primary;
    }

    button {
      border-color: $primary;

      &:before {
        color: $primary;
      }

      &:hover {
        &:before {
          color: $white;
        }
      }
    }
  }
}

#halte-strecken, #bahnland-bayern-karten-plaene, #bahnland-bayern-ausflugstipps, #stationsflyer {
  .tiny-slider {
    margin-bottom: 0 !important;

    .news-slider-item {
      padding: 0 !important;
    }

    button[data-controls=next] {
      right: -10%;

      @include media-breakpoint-down(md) {
        right: -7%;
      }
    }

    button[data-controls=prev] {
      left: 0%;

      @include media-breakpoint-down(md) {
        left: -7%;
      }
    }

    .tns-nav {
      position: relative;
      margin-top: 2rem;
      text-align: right;
      bottom: initial;
      right: initial;
    }
  }
}

#bahnland-bayern-highlights {
  .product {
    max-height: 450px;

    @include media-breakpoint-down(md) {
      max-height: none;
    }

    .image {
      a {
        img {
          max-height: 450px;
          object-fit: contain;
        }
      }
    }

    .content {
      padding: 3rem;

      .submit_container {
       button[type=submit] {
         margin-right: 1rem;
       }
      }
    }
  }
}

.mod_iso_productlist_slider {
  .news-slider-item {
    .image {
      a {
        img {
          max-height: 450px;
          object-fit: contain;
        }
      }
    }
  }
  .downloadlink {
    .submit {
      color: $primary;
    }
  }
}

.bg-primary {
  .mod_iso_productlist_slider {
    .submit {
      color: $white;
    }
  }

}

.hide-headline {
  .content {
    h4, small {
      display: none;
    }
  }
}
