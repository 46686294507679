
body #wrapper {
  .tl_ticket {
    margin-bottom: 4.5rem;
    .odd {
      .price-box {
        background-color: $primary;
      }
    }
    .even {
      .price-box {
        background-color: $secondary;
      }
    }

    .pagination-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin: 2rem 0;
      p {
        margin: 0 auto 1rem auto;
      }
    }
  }
}

.bg-primary-item-text {
  .ce_text {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    padding: 4rem 0;
  }
  .col-md-6 {
    display: flex;
    .inside {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.ticket-list-info {
  display: flex;
  flex-direction: column;

  .ticket-info-item {
    display: flex;

    i {
      margin-top: 0.25rem;
      &.beg-icon-cloud-sun {
        margin-top: 0.05rem;
      }
    }
    .ticket-info-text-ctn {
      margin-left: 0.5rem;
    }

    p {
      margin-left: 0.5rem;
    }
  }
}

