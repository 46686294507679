.flatpickr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .outer-increment-plus,
  .outer-increment-minus {
    padding: .5rem;
    background: #ffffff;
    border: none;
    cursor: pointer;
    min-width: 24px;
    min-height: 24px;

    &:before {
      height: 100%;
      padding: .25rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .outer-increment-plus {
    margin-left: .25rem;
    &:before {
      content: '>';
    }
  }

  .outer-increment-minus {
    margin-right: .25rem;
    &:before {
      content: '<';
    }
  }
}