.mod_registration {
  form {
    .fields {
      display: flex;
      flex-wrap: wrap;

      .widget-text, .widget-password {
        width: 50%;
        padding: 0.5rem 1rem 0.5rem 0;
        input {
          width: 100%;
        }
      }

      .widget-select {
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 0;
      }

    }
  }
}
