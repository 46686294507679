#bbiz-fahrgaeste {

  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 2;
    margin-bottom: $grid-gutter-width * 2;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width * 3.5;
  }

  .background-l0 {
    #bbiz-fahrgaeste-background {
      position: absolute;
      width: 80%;
      height: 100%;
      right: $grid-gutter-width;
      top: -$grid-gutter-width;

      @include media-breakpoint-down(sm) {
        right: 0;
        height: auto;
        top: 0;

        .pfeil {
          display: none;
        }
      }
    }
  }

  > .content {
    @include make-row();

    .figure-left {
      @include make-col-ready();
      @include make-col(12);

      #bbiz-fahrgaeste-left {
        width: 90%;
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);

        #bbiz-fahrgaeste-left {
          width: 70%;
        }
      }
    }

    > .text {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      .headline {
        @include make-row();
        @include make-col-ready();
        @include make-col(12);

        .headline-left {
          @include media-breakpoint-up(lg) {
            @include make-col(9);
          }
        }

        .figure {
          display: none;
          @include media-breakpoint-up(lg) {
            display: block;
            @include make-col-ready();
            @include make-col(3);

          }
        }
      }

      .ce_text > .content-box {
        @include make-row();

        > .content {
          @include make-col-ready();
          @include make-col(12);
          @include media-breakpoint-up(lg) {
            @include make-col(10);
          }
        }
      }
    }
  }
}
