$font-path: "..";
$svg-path: "../../../common/fonts/beg-svg-icons";
$svg-icon-path: "../../fonts/beg-svg-icons";


// Options

$enable-rounded: false;

// Color system
//

// Bootstrap Defaults
$bs-green: #198754;
$bs-red: #dc3545;
$bs-cyan: #0dcaf0;
$bs-yellow: #ffc107;

$black: #000000;
$white: #ffffff;

$blue-dark: #005FA3;
$blue: #93D5F6;
$blue-light: #E8F7FC;
$blue-icon: #034E84;
$light: #F1F7FA;
$cyan: #009FE3;
$yellow: #FFD53B;
$green: #00853d;
$red: #cb0048;
$grey-light: #efefef;

$primary: $blue-dark;
$secondary: $cyan;
$success: $bs-green;
$info: $bs-cyan;
$warning: $bs-yellow;
$danger: $bs-red;

$theme-colors: (
  "primary": $primary,
  "secondary":  $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "green": $green, // Quality Ranking
  "light": $light,
  "blue": $blue,
  "red": $red,
  "blue-light": $blue-light,
  "yellow": $yellow
);

$gradient: linear-gradient(to right, $cyan 10%, $blue-dark 100%);
$gradient-reverse: linear-gradient(to left, $cyan 0%, $blue-dark 70%);

// Body
$body-color: $black;

// Typography

$font-family-roboto: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-poppins: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$font-family-base: $font-family-roboto;

$font-size-base: 1.125rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$font-weight-base: $font-weight-light;

$h1-font-size: 3rem !default;        // 48px
$h2-font-size: 2.25rem !default;     // 36px
$h3-font-size: 1.5rem !default;      // 24px
$h4-font-size: 1.25rem !default;     // 20px
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

$headings-font-weight: $font-weight-bold;
$headings-font-family: $font-family-poppins;
$headings-font-style: italic;
$headings-line-height: 1.06;
$headings-color: $primary;
$headings-margin-bottom: 1.5rem;

$lead-font-size: 1.5rem;
$lead-font-weight: $font-weight-light;

$font-style-italic: italic;

$text-muted: rgba(0, 0, 0, .5);

// Buttons

$btn-transform: skew(-10deg);
$btn-untransform: skew(0deg);
$btn-primary-bg-color: $primary !default;
$btn-primary-color: $white !default;
$btn-font-weight:  $font-weight-extra-bold;
$btn-font-family: $font-family-poppins;
$btn-line-height: 1.5;
$btn-padding-y: 0.3rem;
$btn-padding-x: 2rem;
$btn-border-width: 0;

// Breadcrumbs

$breadcrumb-divider: '>';
$breadcrumb-font-size: $font-size-base*0.8;
$breadcrumb-divider-color: $primary;
$breadcrumb-active-color: $primary;


$form-check-input-border: 1px solid $primary;


// SVG Icons
$icon-press-bayern: url('#{$svg-icon-path}/icon-press-minestry.svg');
$icon-contrast-off: url('#{$svg-path}/Icon_Kontrast.svg');
$icon-search: url('#{$svg-path}/Icon_Suche.svg');
$icon-easy-language-off: url('#{$svg-path}/Icon_easy_lang.svg');
$icon-animation-stop: url('#{$svg-path}/Icon_Animation_Stop.svg');
$icon-color: $blue-icon;

// Forms
$input-group-addon-bg: transparent;

// Badges
$badge-padding-y: .25em;
$badge-padding-x: .35em;

// Pagination
$pagination-hover-color:            $white;
$pagination-hover-bg:               $primary;

$pagination-active-color:           $primary;
$pagination-active-bg:              transparent;
