.ce_huh_syndication {
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;

  a {
    background-color: $white;
    color: $primary;
    padding: 0.25rem;
    background: transparent;
    font-size: 2.25rem;
    border: 2px solid $primary;
    text-decoration: none !important;
    border-radius: 50%;
    margin-right: 15px;
    width: 48px;
    height: 48px;
    &:last-child {
      margin-right: 0px;
    }
    &:hover {
      background-color: $primary;
      color: $white;
    }

    &.syndication-link {
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      &.mail.email {
        &::before {
          font-family: "beg-icons";
          content: "\e917";
        }
      }
      &.facebook {
        &::before {
          font-family: "beg-icons";
          content: "\e918";
        }
      }
      &.twitter {
        &::before {
          font-family: "beg-icons";
          content: "\e919";
        }
      }
      &.linkedin {
        &::before {
          font-family: "beg-icons";
          content: "\e915";
        }
      }
      &.whatsapp {
        &::before {
          font-family: "beg-icons";
          content: "\e916";
        }
      }
      &.xing {
        &::before {
          font-family: "beg-icons";
          content: "\e914";
        }
      }
    }
    .syndication-link__text {
      display: none;
    }
  }
}
