#bbiz-ein-zughalt-pro-sekunde {

  .background-l0 {
    text-align: right;

    #train {
      width: 80%;
      transform: translate(-45%, 30px);

      @include media-breakpoint-down(sm) {
        width: 120%;
        transform: translate(-50%, 30px);
      }
    }
  }

  > .content-wrapper {
    @include make-container();
    max-width: map-get($container-max-widths, xxl);


    > .content {
      @include make-row();

      > .figure {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(md) {
          @include make-col(5);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
          text-align: right;
          margin-bottom: -70px;

          #zughalt-group-left {
            width: 60%;

            .pfeil {
              display: none;
            }
          }
        }
      }

      > .text {
        @include make-col-ready();
        @include make-col(12);

        //padding: 0;

        .h1 small {
          display: block;
        }

        @include media-breakpoint-up(md) {
          @include make-col(7);
          padding-top: $grid-gutter-width * 2;

          .h1 small {
            display: inline;
          }
        }
        @include media-breakpoint-up(lg) {
          @include make-col(6);
          padding-top: $grid-gutter-width * 4;
        }
      }
    }
  }
}
