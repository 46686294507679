.mod_search.search {
  .search-actions {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 3rem;
    .input-group-append {
      button {
        &.btn {
          text-decoration: none !important;
          transform: skew(-10deg);
          text-transform: uppercase;
          background-color: #FFD53B;
          color: #005FA3;
          position: relative;
          border: 0;
          border-radius: 0;
          padding: 0.3rem 2rem;
          font-size: 1.125rem;
          font-weight: 800;
          line-height: 1.5;
          text-align: center;
          vertical-align: middle;
          transition: all 0.1s ease-out;
          font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          &:hover {
            background-color: $primary;
            color: $white;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
