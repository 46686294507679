$icomoon-font-path: "#{$font-path}/beg-icons/fonts";

@import "style";

// support data-icon attribute used by rocksolid_icon_picker
.bhyp-icon[data-icon]:before {
  content: attr(data-icon);
}


@mixin beg-icon-defaults() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin beg-icon($icon, $position:'before') {
  @if $position == 'before' {
    &:before {
      @include beg-icon-defaults();
      content: $icon;
    }
  } @else {
    &:after {
      @include beg-icon-defaults();
      content: $icon;
    }
  }
}

@mixin svg-icon($url, $backgroundColor: none, $position:'before', $posX: 0px, $posY: 0px) {
  @if $position == 'before' {
    &:before {
      content: '';
      display: block;
      height: 2rem;
      width: 2rem;
      @if $backgroundColor == none {
        background: $url no-repeat $posX $posY;
        background-size: contain;
      } @else {
        mask: $url no-repeat $posX $posY;
        background-color: $backgroundColor;
      }
    }
  } @else {
    &:after {
      content: '';
      display: block;
      height: 2rem;
      width: 2rem;
      @if $backgroundColor == none {
        background: $url no-repeat $posX $posY;
        background-size: contain;
      } @else {
        mask: $url no-repeat $posX $posY;
        background-color: $backgroundColor;
      }
    }
  }
}
