.mm-menu {
  --mm-color-background: #{$primary} !important;
  .mm-panels {
    .mm-listitem {
      background-color: transparent !important;
      color: $white !important;
    }
  }

  a.nav-link, a, a:link {
      color: $white !important;
  }

  .mm-btn {
    background-color: transparent !important;
    &:before, &:after {
      border-color: $yellow !important;
    }
  }

  .mm-navbars_bottom {
    .mm-navbar {
      .navbar-secondary {
        border-bottom: 2px solid $yellow;
        margin-left: 2rem;
        margin-right: 2rem;

        a {
          color: $white !important;
        }
      }
      .easy-lang-link a {
        &:before{
          color: $white !important;
          @include media-breakpoint-down(sm) {
            color: $white;
          }
        }
        &:hover {
          &:before{
            color: $yellow;
          }
        }
      }
    }
  }
  #mm-1, #mm-2, #mm-3, #mm-4 {
    // Header of submenu
    .mm-navbar_sticky, .mm-btn {
      .mm-navbar__title {
        color: $white !important;
        font-size: 1.4rem;
      }
      font-weight: bold;
    }
  }
  &#bahnland_navigation_top_mobile.mm-menu_popup {
  
    .active.mm-listitem__text {
      color: $yellow;
    }
  }
}

// Toggler
.sticky-content {
  button.navbar-toggler, .mmenu-toggler{
    border: 2px solid $yellow !important;
    padding: 0.3rem;
    padding-top: 0.4rem;
    padding-left: 0.47rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after  {
      background-color: $white;
    }
  }

  //.navbar-light .navbar-toggler {
  //  color: $secondary;
  //  border: 1px solid $secondary;
  //}
}