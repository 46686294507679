.moby-app {
  #hero {
    position: relative;
    background: $gradient;
    padding-top: 5rem;
    overflow-x: hidden;

    .moby-headline{
      @include media-breakpoint-up(xs){
        padding: 0 2rem;
        h1{
          @include font-size(8.3rem);
        }
        p{
          @include font-size(1.3rem);
        }
      }
      @include media-breakpoint-up(md){
        padding: 0;
        h1{
          @include font-size(6rem);
        }
      }
      @include media-breakpoint-up(xl){
        h1{
          @include font-size(8.3rem);
        }
        p{
          @include font-size(1.8rem);
        }
      }
      position: relative;
      z-index: 3;
      h1{
        line-height: 0.95;
        margin: 0;
        white-space: nowrap;
      }
      h1:nth-of-type(1),
      h1:nth-of-type(2){
        color: $white;
      }
      h1:nth-of-type(2){
        margin-left: 5%;
      }
      h1:nth-child(3),
      .headline-n3 h1{
        color: $yellow;
      }
      p{
        color: $white;
        font-weight: bold;
        line-height: 1.1;
        margin: 0;
      }
    }

    .moby-icon{
      @include media-breakpoint-up(xs){
        justify-content: center;
        img{
          max-width: 100%;
          height: auto;
        }
      }
      @include media-breakpoint-up(md){
        justify-content: start;
        img{
          max-width: 90%;
          height: auto;
        }
      }
      position: absolute;
      bottom: 8%;
      z-index: 2;
      p{
        @include font-size(1.8rem);
        font-weight: 500;
        color: $white;
      }
    }
    .container-mobile{
      @include media-breakpoint-up(xs){
        margin-top: -35%;
      }
      @include media-breakpoint-up(md){
        margin-top: 0;
      }
      position: relative;
      .mobile{
        position: relative;
        max-width: 85%;
        height: auto;
        z-index: 1;
      }
      .qr-codes{
        @include media-breakpoint-up(xs){
          display: none;
        }
        @include media-breakpoint-up(md){
          display: block;
        }
        position: absolute;
        top: 20.5%;
        left: 38.5%;
        max-width: 23%;
        height: auto;
        z-index: 1;
      }
      .download-store{
        @include media-breakpoint-up(xs){
          display: block;
        }
        @include media-breakpoint-up(md){
          display: none;
        }
        position: absolute;
        top: 34%;
        left: 33%;
        width: 33%;
        height: auto;
        z-index: 1;
        img{
          margin-top: 10%;
        }
      }
      .line-top-left, .line-top-right, .train, .bus, .line-middle, .line-bottom-right{
        position: absolute;
        z-index: 0;
      }
      @include media-breakpoint-up(xs){
        .line-top-left, .line-top-right, .line-middle, .line-bottom-right{
          display: none;
        }
      }
      @include media-breakpoint-up(md){
        .line-top-left, .line-top-right, .line-middle, .line-bottom-right{
          display: block;
        }
      }
      .line-top-left{
        top: 5%;
        left: -14%;
        width: 47%;
      }
      .line-top-right{
        top: 5%;
        right: 8%;
        width: 29%;
      }
      .train{
        @include media-breakpoint-up(xs){
          top: 37%;
          left: 13%;
        }
        @include media-breakpoint-up(md){
          top: 15%;
          left: 5%;
        }
        width: 12%;
      }
      .bus{
        @include media-breakpoint-up(xs){
          top: -22%;
        }
        @include media-breakpoint-up(md){
          top: 20%;
        }
        right: 12%;
        width: 12%;
      }
      .line-middle{
        top: 32%;
        left: 56%;
        transform: translateX(-50%);
        width: 140%;
      }
      .line-bottom-right{
        top: 49%;
        right: -29%;
        width: 62%;
      }
    }
  }
}
