.mod_iso_cart {
  .submit_container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    input[type=submit] {
      padding: 0.3rem 2rem;
      font-size: 1.125rem;
      font-weight: $font-weight-bold;
      margin: 1rem 0;
    }
  }
  .cart-table {
    .header, .subtotal, .total {
      font-weight: $font-weight-bold;
    }
    .header {
      border-bottom: 2px solid $primary;
    }
    .table-striped {
      background-color: $white !important;
    }

    .quantity {
      .form-control {
        @include media-breakpoint-up(md) {
          width: 30%;
        }
      }
    }

    a.remove {
     font-size: 0;
      text-decoration: none !important;
      &:after {
        font-family: "beg-icons";
        speak: none;
        font-size: 2rem;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        content: "\e913";
      }
    }
    .image_container img {
      height: auto;
      object-fit: cover;
      max-height: 150px;
      width: 100%;
    }
  }
  .table {  
    --bs-table-striped-bg: $white; 
  }

  .table > :not(:first-child) {
    border-top: 2px solid $primary;
}
}